import Axios from 'axios';
import { useEffect, useState } from 'react';
import { BiSolidDownArrow } from 'react-icons/bi';
import { useSearchParams } from 'react-router-dom';
import BasicButton from '../../Components/NPSportal/basicButton';
import LaminateHover from '../../Components/Products/LaminateHover';
import '../../CSS/tabletops.css';

const TabletopGuide = () => {
  const [tabletops, setTabletops] = useState();
  const [edgeColors, setEdgeColors] = useState();
  const [hoverItem, setHoverItem] = useState();
  // eslint-disable-next-line no-unused-vars
  const [toggleData, setToggleData] = useState(false);
  const [cores, setCores] = useState();
  const [edges, setEdges] = useState();
  const [laminates, setLaminates] = useState();
  const [fabrics, setFabrics] = useState();
  const [phenolics, setPhenolics] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [hoverLam, setHoverLam] = useState();
  const [navButtons, setNavButtons] = useState([]);

  //   // eslint-disable-next-line no-undef
  const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;

  function getTabletopInfo() {
    Axios.get(`${apiAddress}/content/tabletopInfo`).then((response) => {
      setTabletops(response.data);
    });
  }

  function getEdgeColors() {
    Axios.get(`${apiAddress}/content/edgeColors`).then((response) => {
      setEdgeColors(response.data);
    });
  }

  function getEdgeInfo() {
    Axios.get(`${apiAddress}/content/edgeInfo`).then((response) => {
      setEdges(response.data);
    });
  }

  function getCoreInfo() {
    Axios.get(`${apiAddress}/content/coreInfo`).then((response) => {
      setCores(response.data);
    });
  }

  function getSwatches() {
    Axios.get(`${apiAddress}/content/swatches`).then((response) => {
      setLaminates(response.data?.filter((i) => i.config_name === 'WoodLaminates'));
      setFabrics(response.data?.filter((i) => i.config_name === 'DesignOptions'));
      setPhenolics(response.data?.filter((i) => i.config_name === 'CustomPhenolics'));
    });
  }

  function getTableTopButtons() {
    Axios.get(`${apiAddress}/content/getTabletopButtons`)
      .then((response) => {
        setNavButtons(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    const scroll = searchParams?.get('scroll');
    if (scroll) {
      // Delay the scroll to ensure the element is rendered
      const timeout = setTimeout(() => {
        const element = document.getElementById(scroll);
        if (element) {
          element.scrollIntoView();
        }
      }, 100);
      return () => {
        clearTimeout(timeout);
        searchParams.delete('scroll');
        setSearchParams(searchParams);
      };
    }
  }, [searchParams]);

  useEffect(() => {
    getTabletopInfo();
    getEdgeColors();
    getCoreInfo();
    getEdgeInfo();
    getSwatches();
    getTableTopButtons();
  }, []);

  return (
    <>
      <img
        className='banner'
        src='https://res.cloudinary.com/da3rom333/image/upload/v1713550164/Website%20Assets/Tabletops/New/Banner_Finishes_ryhkpu.png'
        alt='table top guide banner'
      ></img>
      <div className='inner-padding'>
        <div className='tabletop-buttons'>
          {navButtons.map((i, index) => (
            <BasicButton
              key={index}
              type={'white-bordered-button'}
              text={i.title}
              onClick={() =>
                document.getElementById(`${i.element_id}`).scrollIntoView({ behavior: 'smooth' })
              }
            />
          ))}
        </div>

        <div>
          <div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              className='parent-titles'
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'baseline',
                  width: '31.5rem',
                  justifyContent: 'space-between',
                }}
              >
                <h4
                  id='tabletop'
                  style={{ margin: '0px' }}
                >
                  Tabletop Guide
                </h4>
                <div style={{ fontSize: '12px', fontStyle: 'italic' }}>
                  Click any tabletop to view care instructions!
                </div>
              </div>
              {/* // <MdSwitchLeft
                  //   style={{
                  //     width: "20px",
                  //     height: "20px",
                  //     verticalAlign: "middle",
                  //     cursor: "pointer",
                  //   }}
                  //   onClick={() => setToggleData(!toggleData)}
                  // /> */}
              {/* // <MdSwitchRight
                  //   style={{
                  //     width: "20px",
                  //     height: "20px",
                  //     verticalAlign: "middle",
                  //     cursor: "pointer",
                  //   }}
                  //   onClick={() => setToggleData(!toggleData)}
                  // /> */}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '11.5rem',
                  justifyContent: 'space-between',
                }}
              >
                {/* <h4
                    style={{
                      margin: "0px",
                      fontSize: "16px",
                      textDecoration: !toggleData ? "underline" : "",
                      cursor: "pointer",
                    }}
                    onClick={() => setToggleData(false)}
                  >
                    Basic Info
                  </h4> */}
                {/* <h4
                    style={{
                      margin: "0px",
                      fontSize: "16px",
                      textDecoration: toggleData ? "underline" : "",
                      cursor: "pointer",
                    }}
                    onClick={() => setToggleData(true)}
                  >
                    Quick Facts
                  </h4> */}
              </div>
            </div>

            <div className='tabletops'>
              {tabletops?.map((i, index) => (
                <div
                  key={index}
                  style={{
                    position: 'relative',
                    display: 'flex',
                    border: '2px solid var(--lightgrey-50)',
                    borderRadius: '10px',
                    height: '190px',
                    padding: '15px 0px',
                    alignItems: 'center',
                  }}
                >
                  <div
                    onMouseEnter={() => setHoverItem(i)}
                    onMouseLeave={() => setHoverItem()}
                    style={{
                      width: '150px',
                      height: '124px',
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <img
                      alt={i.title}
                      style={{
                        alignSelf: 'center',
                        cursor: i.page_num ? 'pointer' : 'default',
                        maxWidth: '150px',
                      }}
                      src={`${i.image}`}
                      onClick={() => {
                        if (i.page_num) {
                          window.open(
                            `https://res.cloudinary.com/da3rom333/image/upload/v1709916878/Product%20Assets/Literature/Maintenance%20and%20Care%20Instructions/HDT_Care_hzwdy9.pdf#page=${i.page_num}&scrollbar=0&toolbar=0&navpanes=0`
                          );
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          if (i.page_num) {
                            window.open(
                              `https://res.cloudinary.com/da3rom333/image/upload/v1709916878/Product%20Assets/Literature/Maintenance%20and%20Care%20Instructions/HDT_Care_hzwdy9.pdf#page=${i.page_num}&scrollbar=0&toolbar=0&navpanes=0`
                            );
                          }
                        }
                      }}
                    ></img>
                  </div>
                  {hoverItem === i && i.longDescription?.length > 0 && true && (
                    <div
                      style={{
                        marginLeft: '-3rem',
                        marginTop:
                          i.longDescription?.length > 700 ? '-24.5rem '
                          : i.longDescription?.length > 600 ? '-20rem'
                          : '-20.2rem',
                        position: 'absolute',
                        zIndex: 2,
                      }}
                      onMouseEnter={() => setHoverItem(i)}
                      onMouseLeave={() => setHoverItem()}
                    >
                      <div className='long-desc'>{i.longDescription}</div>
                      <BiSolidDownArrow
                        size={30}
                        style={{
                          position: 'relative',
                          marginTop: '-8px',
                          marginLeft: '7rem',
                          color: 'var(--darkblue)',
                        }}
                      />
                    </div>
                  )}
                  <div className='bracket'>
                    <div className='line-tabletops'></div>
                  </div>

                  <div className='info-div-tabletops'>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '10px',
                        marginTop: '-13px',
                      }}
                    >
                      <h4
                        style={{ fontSize: '19px', lineHeight: '19px' }}
                        className='info-title'
                      >
                        {i.title}
                      </h4>
                      {/* {i.quickFacts.find((i) => i.title === "cost")?.image && (
                        <img
                          style={{
                            height: "45px",
                            width: "45px",
                            marginLeft: "10px",
                          }}
                          src={`${
                            i.quickFacts.find((i) => i.title === "cost")?.image
                          }`}
                        ></img>
                      )} */}
                    </div>
                    {!toggleData ?
                      Object.keys(i.details).map((j, index, array) => (
                        <h3
                          className='info'
                          key={j}
                        >
                          <span
                            style={{
                              textTransform: 'uppercase',
                              fontWeight: 500,
                            }}
                          >{`${j}: `}</span>
                          <span>{i.details[j]}</span>
                          {index !== array.length - 1 && <p className='info-underline'></p>}
                        </h3>
                      ))
                    : <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ display: 'flex' }}>
                          {i.quickFacts
                            ?.filter((i) => i.title !== 'cost' && i.title !== 'education_level')
                            ?.map((j, index) => (
                              <img
                                key={index}
                                style={{
                                  height: '88px',
                                  width: '88px',
                                  marginRight: '10px',
                                }}
                                src={`${j.image}`}
                                alt='Quick Facts'
                              ></img>
                            ))}
                        </div>
                        <div style={{ display: 'flex' }}>
                          {i.quickFacts
                            ?.filter((i) => i.title === 'education_level')
                            ?.map((j, index) => (
                              <img
                                key={index}
                                style={{
                                  height: '88px',
                                  width: '88px',
                                }}
                                src={`${j.image}`}
                                alt='Quick Facts'
                              ></img>
                            ))}
                        </div>
                      </div>
                    }
                  </div>
                </div>
              ))}
            </div>

            <div>
              <h4
                id='core-info'
                className='parent-titles'
              >
                Core & Edge Information
              </h4>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='cores-div'>
                  {cores?.map((edge, index) => (
                    <div
                      className='core-outer-div'
                      key={index}
                    >
                      <h4 className='core-header'>{edge.title}</h4>
                      <div className='core-div'>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'start',
                            justifyContent: 'space-between',
                            height: '80px',
                          }}
                        >
                          <img
                            className='core-image'
                            src={`${edge.main_image}`}
                            alt={edge.title}
                          ></img>
                          <h3
                            style={{
                              textTransform: 'none',
                              fontSize: '14px',
                              margin: '0px',
                              width: '33rem',
                            }}
                          >
                            {edge.description}
                          </h3>
                        </div>
                        <div
                          style={{
                            padding: '10px',
                            marginTop: '20px',
                            height: '120px',
                            borderRadius: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            background: 'var(--lightgrey-30)',
                          }}
                        >
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img
                              style={{
                                height: '80px',
                              }}
                              src={`${edge.sealed_edge_image}`}
                              alt={edge.title}
                            ></img>
                            <div
                              style={{
                                height: '110px',
                                borderLeft: '2px solid var(--lightgreen)',
                                margin: '0px 20px',
                              }}
                            ></div>
                            <div>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <h3
                                  style={{
                                    fontWeight: '500',
                                    margin: '0px',
                                    color: 'var(--darkblue)',
                                    fontSize: '15px',
                                  }}
                                >
                                  COMPATIBLE EDGES
                                </h3>
                              </div>
                              {edge.edges.map((i, index) => {
                                return (
                                  <img
                                    key={index}
                                    style={{ height: '6rem' }}
                                    src={`${i.info_image}`}
                                    alt={i.edge_type}
                                  ></img>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    height: '1036px',
                  }}
                >
                  {edges?.map((i, index) => (
                    <div
                      key={index}
                      className='edge-div'
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <div className='white-div'></div>
                      <h3 className='edge-type-title'>{i.title}</h3>
                      <img
                        className='edge-image'
                        src={`${i.main_image}`}
                        alt={i.title}
                      ></img>
                      <div className='edge-type-text'>{i.description}</div>
                    </div>
                  ))}
                </div>
              </div>
              <h4
                id='edge-colors'
                className='parent-titles'
              >
                Edge Colors
              </h4>

              <div>
                <div className='edge-title'>
                  <h4 className='info-title'>Standard Edges</h4>
                  <p className='italic-info'>No minimum required</p>
                </div>
                <div className='long-underline'></div>
                <div
                  className='swatch-div'
                  style={{
                    justifyContent: 'space-evenly',
                    marginBottom: '30px',
                  }}
                >
                  {edgeColors?.map((i) =>
                    i.items
                      .filter((k) => k.popular.toLowerCase() === 'true')
                      .map((j, index) => (
                        <div
                          key={index}
                          className='image-div-edges'
                        >
                          <img
                            className='edge-swatch'
                            src={`${j.full_image}`}
                            alt={` ${j.type} ${j.selection_name}`}
                          ></img>
                          <p className='edge-swatch-text'>{`${j.type} | ${j.selection_name}`}</p>
                        </div>
                      ))
                  )}
                </div>

                <div className='edge-title'>
                  <h4 className='info-title'>Premium Edges</h4>
                  <p className='italic-info'>May be subject to minimum quantity and upcharge</p>
                </div>
                <div className='long-underline'></div>
                {edgeColors?.map((i, index) => (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      marginBottom: '20px',
                      alignItems: 'end',
                    }}
                  >
                    <div
                      style={{
                        marginBottom:
                          i.type.length >= 16 ? '-.5px'
                          : i.type.length >= 12 ? '19px'
                          : '45px',
                        textTransform: 'uppercase',
                      }}
                      className=' info-title vertical-text'
                    >
                      <span>{i.type}</span>
                    </div>
                    <div
                      style={{ display: 'flex', alignItems: 'center' }}
                      className='bracket'
                    >
                      <div
                        className='swatch-div'
                        style={{
                          justifyContent: 'space-evenly start',
                          padding: '10px 0px',
                        }}
                      >
                        {i.items
                          .filter((k) => k.popular.toLowerCase() !== 'true')
                          .map((j, index) => (
                            <div
                              key={index}
                              className='image-div-edges'
                            >
                              <img
                                className='edge-swatch'
                                src={`${j.full_image || j.swatch}`}
                                alt={j.selection_name}
                              ></img>
                              <p className='edge-swatch-text'>{`${j.selection_name}(${j.config_value})`}</p>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <h4
                id='laminate-colors'
                className='parent-titles'
              >
                Laminate Colors
              </h4>
              <div>
                <div
                  style={{ flexDirection: 'column', alignItems: 'start' }}
                  className='edge-title'
                >
                  <div className='edge-title'>
                    <h4 className='info-title'>Standard Laminates</h4>
                    <p className='italic-info'>Don't see it? Just ask!</p>
                  </div>
                  <div
                    style={{
                      width: '100%',
                      border: '1px solid #145A7F',
                      marginBottom: '30px',
                    }}
                    className='long-underline'
                  ></div>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(19, 1fr)',
                      alignItems: 'start',
                      justifyContent: 'start',
                      gap: '10.9px',
                    }}
                  >
                    {laminates
                      ?.filter((i) => i?.type?.toLowerCase() === 'standard')
                      ?.map((laminate, index) => (
                        <div
                          key={index}
                          onMouseEnter={() => setHoverLam(laminate)}
                          onMouseLeave={() => setHoverLam()}
                        >
                          <div
                            style={{
                              width: '4rem',
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                            }}
                          >
                            <img
                              id={`${laminate?.selection_name}`}
                              style={{
                                height: '3.5rem',
                                width: '4rem',
                                borderRadius: '6px',
                              }}
                              src={`${laminate.swatch}`}
                              alt={laminate.selection_name}
                            ></img>
                            <h3
                              style={{
                                width: '4rem',
                                fontSize: '10px',
                                textAlign: 'center',
                              }}
                            >{`${laminate.selection_name} (${laminate.config_value})`}</h3>
                          </div>
                          {hoverLam?.selection_name === laminate.selection_name && (
                            <LaminateHover
                              colorData={{
                                selectionName: hoverLam.selection_name,
                                Swatch: hoverLam.swatch,
                                value: hoverLam.config_value,
                                Type: hoverLam.type,
                                imageLink: hoverLam.image_link,
                              }}
                            />
                          )}
                        </div>
                      ))}
                  </div>
                  <div
                    style={{ marginTop: '20px' }}
                    className='edge-title'
                  >
                    <h4 className='info-title'>Premium Laminates</h4>
                    <p className='italic-info'>May be subject to minimum quantity and upcharge</p>
                  </div>
                  <div
                    style={{
                      width: '100%',
                      border: '1px solid #145A7F',
                      marginBottom: '30px',
                    }}
                    className='long-underline'
                  ></div>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(19, 1fr)',
                      alignItems: 'start',
                      justifyContent: 'start',
                      gap: '10.8px',
                    }}
                  >
                    {laminates
                      ?.filter((i) => i?.type?.toLowerCase() === 'premium')
                      ?.map((laminate, index) => (
                        <div
                          key={index}
                          onMouseEnter={() => setHoverLam(laminate)}
                          onMouseLeave={() => setHoverLam()}
                        >
                          <div
                            style={{
                              width: '4rem',
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                            }}
                          >
                            <img
                              id={`${laminate?.selection_name}`}
                              style={{
                                height: '3.5rem',
                                width: '4rem',
                                borderRadius: '6px',
                              }}
                              src={`${laminate.swatch}`}
                              alt={laminate.selection_name}
                            ></img>
                            <h3
                              style={{
                                width: '4rem',
                                fontSize: '10px',
                                textAlign: 'center',
                              }}
                            >{`${laminate.selection_name} (${laminate.config_value})`}</h3>
                          </div>
                          {hoverLam?.selection_name === laminate.selection_name && (
                            <LaminateHover
                              colorData={{
                                selectionName: hoverLam.selection_name,
                                Swatch: hoverLam.swatch,
                                value: hoverLam.config_value,
                                Type: hoverLam.type,
                                imageLink: hoverLam.image_link,
                              }}
                            />
                          )}
                        </div>
                      ))}
                  </div>
                </div>
                <h4
                  className='parent-titles'
                  id='custom-fabrics'
                >
                  Custom Fabrics
                </h4>
                <div>
                  <div
                    style={{ flexDirection: 'column', alignItems: 'start' }}
                    className='edge-title'
                  >
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(19, 1fr)',
                        alignItems: 'start',
                        justifyContent: 'start',
                        gap: '10.9px',
                      }}
                    >
                      {fabrics?.map((fabric, index) => (
                        <div
                          key={index}
                          style={{
                            width: '4rem',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          <img
                            style={{
                              height: '3.5rem',
                              width: '4rem',
                              borderRadius: '6px',
                            }}
                            src={`${fabric.full_image}`}
                            alt={fabric.selection_name}
                          ></img>
                          <h3
                            style={{
                              width: '4rem',
                              fontSize: '10px',
                              textAlign: 'center',
                            }}
                          >{`${fabric.selection_name} (${fabric.config_value})`}</h3>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <h4
                  className='parent-titles'
                  id='custom-phenolics'
                >
                  Custom Phenolics
                </h4>
                <div>
                  <div
                    style={{ flexDirection: 'column', alignItems: 'start' }}
                    className='edge-title'
                  >
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(19, 1fr)',
                        alignItems: 'start',
                        justifyContent: 'start',
                        gap: '10.9px',
                      }}
                    >
                      {phenolics?.map((i, index) => (
                        <div
                          key={index}
                          style={{
                            width: '4rem',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          <img
                            style={{
                              height: '3.5rem',
                              width: '4rem',
                            }}
                            src={`${i.full_image}`}
                            alt={i.selection_name}
                          ></img>
                          <h3
                            style={{
                              width: '4rem',
                              fontSize: '10px',
                              textAlign: 'center',
                            }}
                          >{`${i.selection_name}`}</h3>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div style={{ height: '5rem' }}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TabletopGuide;
