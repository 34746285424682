import { AdvancedImage, AdvancedVideo } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';
import { getPage } from '@cloudinary/url-gen/actions/extract';
import clipboardCopy from 'clipboard-copy';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { useEffect, useRef, useState } from 'react';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { LuDownload } from 'react-icons/lu';
import { SlClose } from 'react-icons/sl';
import { TbLink } from 'react-icons/tb';
import { useSelector } from 'react-redux';
import '../../CSS/ImageViewer.css';
import '../../CSS/MediaPopup.css';
import '../../CSS/ViewAll.css';
import '../../CSS/MobileApp/M_ViewAll.css';
import decodeCloudinary from '../../hooks/decodeCloudinary';
import useAuth from '../../hooks/useAuth';
import CarouselDots from '../Shared/CarouselDots';
import FormatImageLink from '../Shared/FormatImageLink';
import LoadingIcon from '../Shared/LoadingIcon';
import MediaTile from './MediaTile';

const ViewAll = ({ setDownloadMedia }) => {
  const { auth } = useAuth();
  const isMobile = useSelector((state) => state.isMobile.value.isMobile);
  const { product, parentCat } = useSelector((state) => state.productState.value);
  const [imageLinks, setImageLinks] = useState();
  const [mainImage, setMainImage] = useState();
  // const [selectedType, setSelectedType] = useState('All Images'); //All Images, Product, Details, Environment, Literature
  //const [selectAll, setSelectAll] = useState(false);
  const [clearAll, setClearAll] = useState(false);
  const [selectedList, setSelectedList] = useState([]);
  const [videoPlayer, setVideoPlayer] = useState(false);
  // const [sellSheet, setSellSheet] = useState();
  // const [sellSheetPageCount, setSellSheetPageCount] = useState();
  const [literature, setLiterature] = useState();
  const [multiplePages, setMultiplePages] = useState(false);
  const [videos, setVideos] = useState();
  const [linkCopied, setLinkCopied] = useState(false);
  // const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;
  const decodeCloudinaryURL = decodeCloudinary();
  // function getData() {
  //   Axios.post(`${apiAddress}/products/getProductImageLinks`, {
  //     sku: sku,
  //   }).then((response) => {
  //     setImageLinks(response.data.media.productImages);
  //     setMainImage(response.data.media.productImages[0]);
  //     // setSellSheet(response.data.images.sellSheet.link);
  //     // setSellSheetPageCount(response.data.images.sellSheet.pages)
  //     setLiterature(response.data.media.literature);
  //     setVideos(response.data.media.videos);
  //   });
  // }

  async function handleDownloadClick() {
    const zip = new JSZip();
    const folder = zip.folder('Images.zip'); // folder name where all files will be placed in
    selectedList.forEach((url) => {
      const blobPromise = fetch(
        `https://res.cloudinary.com/da3rom333/image/upload/fl_attachment/${url}`
      ).then((r) => {
        if (r.status === 200) {
          return r.blob();
        }
        return Promise.reject(new Error(r.statusText));
      });
      //   const name = url?.substring(url.lastIndexOf("/") + 1);
      const name = url?.substring(url?.split('/', 3).join('/')?.length + 1);
      folder.file(name, blobPromise);
    });

    zip.generateAsync({ type: 'blob' }).then((blob) => saveAs(blob, 'Images.zip'));
  }
  useEffect(() => {
    if (product) {
      setImageLinks(product.cdnLinks);
      setMainImage(product.cdnLinks[0].Image);
      setLiterature(product.literature);
      setVideos(product.videos);
    }
  }, [product]);
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'da3rom333',
    },
  });

  function changeImageRight() {
    setMainImage();
    const index = imageLinks.findIndex((item) => item.Image === mainImage);
    if (index + 1 >= imageLinks.length) {
      setMainImage(imageLinks[0]?.Image);
    } else {
      setMainImage(imageLinks[index + 1]?.Image);
    }
  }
  function changeImageLeft() {
    setMainImage();
    const index = imageLinks.findIndex((item) => item.Image === mainImage);
    if (index <= 0) {
      setMainImage(imageLinks[imageLinks.length - 1].Image);
    } else {
      setMainImage(imageLinks[index - 1].Image);
    }
  }
  function changeImage(newIndex) {
    setMainImage(imageLinks[newIndex].Image);
  }

  //for mobile swipe
  const touchStartX = useRef(null);
  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    if (!touchStartX.current) {
      return;
    }

    const touchEndX = e.touches[0].clientX;
    const deltaX = touchEndX - touchStartX.current;
    const threshold = 100;

    if (deltaX > threshold) {
      changeImageLeft();
      handleTouchEnd();
    } else if (deltaX < -threshold) {
      changeImageRight();
      handleTouchEnd();
    }
  };

  const handleTouchEnd = () => {
    touchStartX.current = null;
  };

  function addToSelected(i) {
    if (selectedList.length) {
      setSelectedList([...selectedList, i]);
    } else {
      setSelectedList([i]);
    }
  }
  function removeFromSelected(imageLink) {
    setSelectedList(selectedList?.filter((i) => i !== imageLink));
  }

  useEffect(() => {
    if (imageLinks?.includes(mainImage)) {
      setMultiplePages(false);
    }
  }, [mainImage]);
  useEffect(() => {
    setTimeout(() => {
      setLinkCopied(false);
    }, 400);
  }, [linkCopied]);

  const decodeAndFormat = FormatImageLink();
  return (
    <div className='view-all'>
      <div className='modal'></div>
      <div
        className='media-popup'
        style={{ top: '15rem', height: '850px' }}
      >
        <div className='headers'>
          <div className='media-type-links'>
            {/* <div
              onClick={() => {
                setSelectedType("All Images");
              }}
              className={`media-header ${
                selectedType === "All Images" ? "selected-type" : ""
              }`}
            >
              All Images
            </div>
            <div
              className={`media-header ${
                selectedType === "Product" ? "selected-type" : ""
              }`}
              onClick={() => {
                setSelectedType("Product");
              }}
            >
              Product
            </div>
            <div
              className={`media-header ${
                selectedType === "Details" ? "selected-type" : ""
              }`}
              onClick={() => {
                setSelectedType("Details");
              }}
            >
              Details
            </div>
            <div
              className={`media-header ${
                selectedType === "Environment" ? "selected-type" : ""
              }`}
              onClick={() => {
                setSelectedType("Environment");
              }}
            >
              Environment
            </div>
            <div
              className={`media-header ${
                selectedType === "Literature" ? "selected-type" : ""
              }`}
              onClick={() => {
                setSelectedType("Literature");
              }}
            >
              Literature
            </div> */}
          </div>
          <div className='media-download-buttons'>
            {auth?.rep ?
              <div className='media-download-buttons-div'>
                <button
                  onClick={() => {
                    const holderArray = [];
                    imageLinks.forEach((item) => {
                      holderArray.push(decodeCloudinaryURL(item.Image));
                    });
                    setSelectedList(holderArray);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      const holderArray = [];
                      imageLinks.forEach((item) => {
                        holderArray.push(decodeCloudinaryURL(item.Image));
                      });
                      setSelectedList(holderArray);
                    }
                  }}
                >
                  Select All Images
                </button>
                <button
                  onClick={() => {
                    setClearAll(true);
                    setSelectedList([]);
                    setTimeout(() => {
                      setClearAll(false);
                    }, 500);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setClearAll(true);
                      setSelectedList([]);
                      setTimeout(() => {
                        setClearAll(false);
                      }, 500);
                    }
                  }}
                >
                  Clear Selection
                </button>
                <button
                  className={`${
                    selectedList ?
                      selectedList?.length ?
                        ''
                      : 'inactive'
                    : 'inactive'
                  }`}
                  onClick={handleDownloadClick}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleDownloadClick();
                    }
                  }}
                >
                  Download Selected ({selectedList.length})
                </button>
              </div>
            : ''}
            <SlClose
              onClick={() => {
                setDownloadMedia(false);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setDownloadMedia(false);
                }
              }}
              className='close-button'
            />
          </div>
        </div>
        <div className='flex'>
          <div className='flex-center'>
            <BsChevronLeft
              onClick={changeImageLeft}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  changeImageLeft();
                }
              }}
            />
            <div
              className='main-image'
              role='button'
              aria-label=''
              tabIndex={0}
              onClick={() => {
                // eslint-disable-next-line no-console
                console.log(multiplePages, mainImage);
              }}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  // eslint-disable-next-line no-console
                  console.log(multiplePages, mainImage);
                }
              }}
            >
              {multiplePages ?
                <div
                  style={{
                    height: '692px',
                    overflow: 'auto',
                    display: 'flex',
                    alignItem: 'center',
                    flexDirection: 'column',
                  }}
                >
                  {[...Array(multiplePages)].map((i, index) => {
                    return (
                      <AdvancedImage
                        key={index}
                        cldImg={decodeAndFormat(mainImage)
                          .format('png')
                          .extract(getPage().byNumber(index + 1))}
                      />
                    );
                  })}
                </div>
              : videoPlayer ?
                <AdvancedVideo
                  cldVid={cld.video(
                    decodeURIComponent(
                      mainImage
                        .substring(mainImage.indexOf('/upload/') + '/upload/'.length)
                        .replace('.mp4', '')
                    )
                  )}
                  controls={true}
                  autoPlay={true}
                />
              : <AdvancedImage cldImg={cld.image(decodeCloudinaryURL(mainImage)).format('png')} />}
              {isMobile && (auth?.roles?.includes(3500) || auth?.roles?.includes(3001)) ?
                <span className='main-image-buttons-span'>
                  <LuDownload
                    onClick={() => {
                      videoPlayer ?
                        window.open(`${mainImage}`)
                      : window.open(
                          `https://res.cloudinary.com/da3rom333/image/upload/fl_attachment/${mainImage}`,
                          '_blank'
                        );
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        videoPlayer ?
                          window.open(`${mainImage}`)
                        : window.open(
                            `https://res.cloudinary.com/da3rom333/image/upload/fl_attachment/${mainImage}`,
                            '_blank'
                          );
                      }
                    }}
                  />
                  <TbLink
                    onClick={() => {
                      setLinkCopied(true);
                      videoPlayer ?
                        clipboardCopy(`${mainImage}`)
                      : clipboardCopy(
                          `https://res.cloudinary.com/da3rom333/image/upload/${mainImage}`
                        );
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        setLinkCopied(true);
                        videoPlayer ?
                          clipboardCopy(`${mainImage}`)
                        : clipboardCopy(
                            `https://res.cloudinary.com/da3rom333/image/upload/${mainImage}`
                          );
                      }
                    }}
                  />
                  {linkCopied && (
                    <div
                      className='notice link-copied'
                      style={{ left: '50%' }}
                    >
                      Link Copied
                    </div>
                  )}
                </span>
              : ''}
            </div>
            <BsChevronRight
              onClick={changeImageRight}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  changeImageRight();
                }
              }}
            />
            {isMobile && (
              <>
                <CarouselDots
                  images={imageLinks}
                  index={imageLinks?.findIndex((item) => item.Image === mainImage)}
                  setIndex={changeImage}
                ></CarouselDots>
                {auth?.roles?.includes(3500) || auth?.roles?.includes(3001) ?
                  <div className='view-all-media-navbar'></div>
                : ''}
              </>
            )}
          </div>
          <div className='media'>
            <h4>Images</h4>
            <div className='underline'></div>
            <div className='images-wrapper'>
              <div className='images-div'>
                {imageLinks ?
                  <>
                    {imageLinks?.map((i) => {
                      return (
                        <MediaTile
                          image={decodeCloudinaryURL(i.Image)}
                          key={i.Image}
                          setMainImage={setMainImage}
                          mainImage={mainImage}
                          //selectAll={selectAll}
                          addToSelected={addToSelected}
                          removeFromSelected={removeFromSelected}
                          clearList={clearAll}
                          selectedList={selectedList}
                          auth={auth}
                          setMultiplePages={setMultiplePages}
                          setVideoPlayer={setVideoPlayer}
                        />
                      );
                    })}
                  </>
                : <div
                    style={{
                      // height: "500px",
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <LoadingIcon />
                  </div>
                }
              </div>
            </div>
            <h4
              onClick={() => {
                // eslint-disable-next-line no-console
                console.log(literature);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  // eslint-disable-next-line no-console
                  console.log(literature);
                }
              }}
            >
              Literature & Videos
            </h4>
            <div className='underline'></div>
            <div className='lit-div'>
              {literature?.map((i) => {
                return (
                  <MediaTile
                    image={i.link}
                    key={i.link}
                    setMainImage={setMainImage}
                    mainImage={mainImage}
                    //selectAll={selectAll}
                    addToSelected={addToSelected}
                    removeFromSelected={removeFromSelected}
                    clearList={clearAll}
                    selectedList={selectedList}
                    pdf={true}
                    setMultiplePages={setMultiplePages}
                    auth={auth}
                    pageCount={i.pageCount}
                    setVideoPlayer={setVideoPlayer}
                  />
                );
              })}

              {videos?.map((i) => {
                return (
                  <MediaTile
                    image={i.video_link}
                    key={i.video_link}
                    setMainImage={setMainImage}
                    mainImage={mainImage}
                    //selectAll={selectAll}
                    addToSelected={addToSelected}
                    removeFromSelected={removeFromSelected}
                    clearList={clearAll}
                    selectedList={selectedList}
                    video={true}
                    auth={auth}
                    setVideoPlayer={setVideoPlayer}
                    setMultiplePages={setMultiplePages}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <button
          style={{
            position: 'absolute',
            right: '2rem',
            bottom: '3rem',
            border: '1px solid #acacac',
            borderRadius: '2rem',
            padding: '.5rem 1rem',
            background: 'none',
            fontFamily: 'poppins',
            cursor: 'pointer',
          }}
          onClick={() => {
            window.open(`/media?Category=${parentCat.catName}`);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              window.open(`/media?Category=${parentCat.catName}`);
            }
          }}
        >
          Go To Media Downloads
        </button>
      </div>
    </div>
  );
};

export default ViewAll;
