const icon =
  'https://res.cloudinary.com/da3rom333/image/upload/v1689011225/Website%20Assets/GIFs/LoadingBig_pmqwoe.gif';
const Loading = () => {
  return (
    <div>
      <div className='modal'></div>
      <img
        src={icon}
        style={{
          zIndex: '151',
          position: 'absolute',
          top: '25rem',
          left: '50rem',
          height: '15rem',
        }}
        alt='Loading Icon'
      />
    </div>
  );
};

export default Loading;
