import { useEffect, useState } from 'react';
import { FaCartPlus } from 'react-icons/fa';
import { VscFilePdf } from 'react-icons/vsc';
import { useDispatch } from 'react-redux';
import { updateProductInfo } from '../../ReduxSlices/productState';
import { useCart } from '../../context/CartContext';
import { useStageInfo } from '../../context/StageProvider';
import useAuth from '../../hooks/useAuth';
import useUserInfo from '../../hooks/useUserInfo';
import ProductList from './ProductList';

function AddToCart() {
  const {
    cartProducts,
    groupByProduct,
    steps,
    guardrails,
    skirting,
    stageDollies,
    riserDollies,
    resetStageStates,
    exportPDF,
    buildDims,
    capacity,
    imageRef,
  } = useStageInfo();
  const { auth } = useAuth();
  const { displayLoginBox, displayBoolean } = useUserInfo();
  const { addToCart } = useCart();
  const dispatch = useDispatch();
  const [tryAddToCart, setTryAddToCart] = useState();

  async function handleAddBuildToCart() {
    const allProducts = [
      ...cartProducts,
      ...groupByProduct(steps),
      ...groupByProduct(guardrails.filter((g) => !steps.find((s) => s.id === g.id))),
      ...groupByProduct(skirting.filter((g) => !steps.find((s) => s.id === g.id))),
    ];
    if (stageDollies) {
      allProducts.push(stageDollies);
    }
    if (riserDollies) {
      allProducts.push(riserDollies);
    }
    await addItemToCart(allProducts, 0);
    window.open('/cart', '_blank');
    resetStageStates();
  }

  //runs recursively, adding one product to the cart at a time
  async function addItemToCart(allProducts, index) {
    if (index >= allProducts.length) {
      return;
    }
    const item = allProducts[index];
    const optionsSelected = {};
    Object.keys(item.product.configOptions).forEach((option) => {
      optionsSelected[item.product.configOptions[option].configName] =
        item.product.configOptions[option].selectionName;
    });
    const response = await addToCart(
      item.product.productInfo.FreightQuoteModelNum,
      item.quantity,
      item.modelNum,
      item.product.productInfo.ShortDescription,
      item.product.cdnLinks[0]?.Image,
      optionsSelected,
      item.product.productInfo.BaseModelNum,
      parseInt(item.product.productInfo.Quantity) * parseInt(item.quantity),
      parseInt(item.product.productInfo.Quantity),
      item.product.productInfo.ShipsNonBoxed,
      false,
      item.product.productInfo.Status
    );
    if (response) {
      dispatch(updateProductInfo({ notification: response }));
    }
    //call this function recursively for the next index
    await addItemToCart(allProducts, index + 1);
  }

  async function handleOpenAddToCart() {
    if (auth?.rep) {
      handleAddBuildToCart();
      setTryAddToCart();
    } else {
      displayLoginBox();
      setTryAddToCart(true);
    }
  }

  useEffect(() => {
    if (!displayBoolean && auth?.rep && tryAddToCart) {
      handleOpenAddToCart();
    }
  }, [displayBoolean]);

  return (
    <div className='add-stage-to-cart'>
      {cartProducts && (
        <>
          <div>
            <ProductList />
          </div>
          <div
            style={{ width: '370px' }}
            className='addToCart-buttons-wrapper'
          >
            <div
              role='button'
              aria-label='Export PDF'
              tabIndex={0}
              style={{ fontSize: '14px' }}
              id='stage-pdf'
              className='addToCart-button'
              onClick={() =>
                exportPDF(
                  cartProducts,
                  steps,
                  guardrails,
                  skirting,
                  buildDims,
                  groupByProduct,
                  capacity,
                  imageRef,
                  stageDollies,
                  riserDollies
                )
              }
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  exportPDF(
                    cartProducts,
                    steps,
                    guardrails,
                    skirting,
                    buildDims,
                    groupByProduct,
                    capacity,
                    imageRef,
                    stageDollies,
                    riserDollies
                  );
                }
              }}
            >
              <VscFilePdf /> Export PDF
            </div>
            <div
              role='button'
              aria-label='Add To Cart'
              tabIndex={0}
              style={{ fontSize: '14px' }}
              id='stage-addToCart'
              className='addToCart-button'
              onClick={handleOpenAddToCart}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleOpenAddToCart();
                }
              }}
            >
              <FaCartPlus /> Add To Cart
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default AddToCart;
