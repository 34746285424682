import { useEffect, useState } from 'react';
import { IoWarning } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import '../../CSS/tabletops.css';
import LaminateBar from './LaminateBar';
import OptionsCube from './OptionsCube';

const OptionsBar = ({ configTypes, configSelected, configSelectedList, setInfoHover }) => {
  const {
    optionsSelected,
    allProducts,
    filterExceptionList,
    product,
    view3d,
    configuraMissingColors,
  } = useSelector((state) => state.productState.value);
  const [availableOptions, setAvailableOptions] = useState(new Set());
  const [flatConfigTypes, setFlatConfigTypes] = useState();
  const holderSet = new Set();

  function getFlatConfigTypes() {
    var obj = {};
    Object.keys(configTypes).map((i) =>
      Object.keys(configTypes[i]).map((j) => {
        Object.keys(configTypes[i][j]).map((k) => {
          obj[k] = configTypes[i][j][k];
        });
      })
    );
    setFlatConfigTypes(obj);
  }

  function allOptions() {
    for (let i = 0; i < flatConfigTypes[configSelected.name]?.values?.length || 0; i++) {
      holderSet.add(flatConfigTypes[configSelected.name]?.values[i]?.selectionName);
    }
    setAvailableOptions(holderSet);
  }

  function filterOptions() {
    let allCheck = true;
    if (filterExceptionList.includes(configSelected.name)) {
      allOptions();
    } else {
      for (var i in optionsSelected) {
        if (optionsSelected[i] !== false) {
          allCheck = false;
          break;
        }
      }
      if (allCheck) {
        allOptions();
      }
      if (!allCheck) {
        var options = {};
        //get a minimized list of options that are selcted (optionsSelected has values that are false as placeholders)
        for (var j in optionsSelected) {
          if (
            optionsSelected[j] !== false &&
            j !== configSelected.name &&
            filterExceptionList.indexOf(j) < 0
          ) {
            options[j] = optionsSelected[j];
          }
        }
        // Loop through all products to get the items where all options are true. return just the options for configSelected in a set
        // Check when holderSet length equals all option possibilities (configtypes[key].values.length)
        if (Object.keys(options).length > 0) {
          for (let k = 0; k < allProducts.length; k++) {
            let add = true;
            for (var l in options) {
              if (l in allProducts[k].configOptions) {
                if (
                  options[l] !== allProducts[k].configOptions[l].selectionName &&
                  optionsSelected[l] !== false
                ) {
                  add = false;
                  break;
                }
              } else {
                add = false;
                break;
              }
            }
            if (configSelected.name in allProducts[k].configOptions) {
              if (add) {
                holderSet.add(allProducts[k].configOptions[configSelected.name].selectionName);
              }
            }
          }
          setAvailableOptions(holderSet);
        } else {
          allOptions();
        }
      }
    }
  }

  function getElements() {
    var elementsArray = [];
    if (configSelected.name === 'Premium Edge Color') {
      configSelected.values
        .filter((i) => {
          return i.Type === product?.configOptions?.Edge?.selectionName;
        })
        .map((i) => {
          elementsArray.push(
            <OptionsCube
              key={i.selectionName}
              option={i}
              optionType={configSelected}
              availableOptions={availableOptions}
            />
          );
        });
    } else {
      for (let i = 0; i < configSelected?.values?.length; i++) {
        elementsArray.push(
          <OptionsCube
            key={i}
            option={configSelected?.values[i]}
            optionType={configSelected}
            availableOptions={availableOptions}
          />
        );
      }
    }
    return elementsArray;
  }

  function switchDisplay() {
    if (flatConfigTypes) {
      if (filterExceptionList.includes(configSelected.name)) {
        switch (configSelected.name) {
          case 'Stool Color':
            return <div className='options-bar'>{getElements()}</div>;
          case 'Premium Edge Color':
            return (
              <div className='edgecolors'>
                {/* <h3 className="product-option-title">Standard Options</h3>
                <div>{getElements().filter((i) => i.key === "Black")}</div>
                <h3 className="product-option-title">Premium Options</h3>
                <div>{getElements().filter((i) => i.key !== "Black")}</div> */}
                {getElements().filter((i) => i?.props?.option?.Popular === 1).length > 0 && (
                  <div className='options-bar-div'>
                    <h3 className='product-option-title'>Standard Options</h3>
                    <div className='options-bar'>
                      {getElements().filter((i) => i?.props?.option?.Popular === 1)}
                    </div>
                  </div>
                )}
                {getElements().filter((i) => i?.props?.option?.Popular === 0).length > 0 && (
                  <div className='options-bar-div'>
                    <h3 className='product-option-title'>Premium Options</h3>
                    <div className='premiumedges options-bar'>
                      {getElements().filter((i) => i?.props?.option?.Popular === 0)}
                    </div>
                  </div>
                )}
              </div>
            );
          default:
            return (
              <div className='options-bar'>
                <LaminateBar
                  configSelected={configSelected}
                  laminates={flatConfigTypes[configSelected.name].values}
                  setInfoHover={setInfoHover}
                  parentType={'productPage'}
                />
              </div>
            );
        }
      }
      return <div className='options-bar'>{getElements()}</div>;
    }
  }

  function moreInfoPage(link) {
    window.open(`/tabletopguide?scroll=${link}`);
  }

  useEffect(() => {
    getFlatConfigTypes();
  }, []);

  useEffect(() => {}, []);

  useEffect(() => {
    if (flatConfigTypes) {
      filterOptions();
    }
  }, [configSelected, optionsSelected, flatConfigTypes]);

  return (
    <div className='options-bar-div'>
      {Object.keys(configSelectedList)?.length > 1 ?
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <h3 className='product-option-title'>{configSelected.name} Options:</h3>
          {configSelected.custom_option ?
            <div className='basic-flex'>
              <div
                role='button'
                aria-label={`See More ${configSelected.name} Information`}
                tabIndex={0}
                className='tabletop-more-info-button'
                onClick={() => moreInfoPage(configSelected.custom_option)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    moreInfoPage(configSelected.custom_option);
                  }
                }}
              >
                <img
                  src='https://res.cloudinary.com/da3rom333/image/upload/v1716472760/Website%20Assets/All%20Icons/New%20Icons/Icon_Finishes_v1kvlt.svg'
                  alt='Icon Finishes'
                />
                <span style={{ marginLeft: '3px' }}>
                  See More {configSelected.name} Information
                </span>
              </div>
            </div>
          : ''}
        </div>
      : configSelected.custom_option ?
        <div
          style={{ width: '100%', justifyContent: 'center' }}
          className='basic-flex'
        >
          <div
            role='button'
            aria-label={`See More ${configSelected.name} Information`}
            tabIndex={0}
            className='tabletop-more-info-button'
            onClick={() => moreInfoPage(configSelected.custom_option)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                moreInfoPage(configSelected.custom_option);
              }
            }}
          >
            <img
              src='https://res.cloudinary.com/da3rom333/image/upload/v1716472760/Website%20Assets/All%20Icons/New%20Icons/Icon_Finishes_v1kvlt.svg'
              alt='See more Information'
            />
            <span style={{ marginLeft: '3px' }}> See More {configSelected.name} Information </span>
          </div>
        </div>
      : ''}
      {switchDisplay()}
      {view3d &&
        configuraMissingColors[configSelected.name] &&
        configuraMissingColors[configSelected.name] === optionsSelected[configSelected.name] && (
          <div className='configura-color-not-found'>
            <IoWarning />
            This color is not available for visualization.
          </div>
        )}
      {filterExceptionList.includes(configSelected.name) && (
        <div className='color-disclaimer'>
          <span>Colors are for visualization purposes only. </span>
          <Link to='/contact?category=product-information&form=request-catalog%2Fswatch'>
            Request a physical swatch
          </Link>
          <span> to review exact colors.</span>
        </div>
      )}
    </div>
  );
};

export default OptionsBar;
