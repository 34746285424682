import { useEffect } from 'react';
import { useStageInfo } from '../../context/StageProvider';
import ToggleButton from '../FreightCart/ToggleButton';

function DimensionsReference() {
  const {
    setSpaceArea,
    areaDimensions,
    setAreaDimensions,
    toggle,
    setToggle,
    unitsInInches,
    setunitsInInches,
    maxAreaWidth,
    maxAreaLength,
    configure,
  } = useStageInfo();

  useEffect(() => {
    if (toggle) {
      setSpaceArea(areaDimensions);
    } else {
      setSpaceArea();
    }
  }, [toggle, areaDimensions]);

  return (
    <div style={{ display: configure ? 'none' : 'block' }}>
      <div className='stage-references'>
        <div id='space-dimensions'>
          <span>SPACE DIMENSIONS</span>
          <div className='toggle-feet-inches'>
            <span>Feet (')</span>
            <span>
              <ToggleButton
                state={unitsInInches}
                setState={setunitsInInches}
              />
            </span>
            <span>Inches (")</span>
          </div>
          <div className='space-dimensions-options'>
            <input
              type='number'
              min={0}
              max={maxAreaWidth}
              className='space-dimension'
              value={unitsInInches ? areaDimensions.width : areaDimensions.width / 12}
              onChange={(e) =>
                setAreaDimensions({
                  ...areaDimensions,
                  width: unitsInInches ? e.target.value : e.target.value * 12,
                })
              }
            ></input>
            <span>{`Width (${unitsInInches ? '"' : "'"})`}</span>
            <input
              type='number'
              min={0}
              max={maxAreaLength}
              className='space-dimension'
              value={unitsInInches ? areaDimensions.length : areaDimensions.length / 12}
              onChange={(e) =>
                setAreaDimensions({
                  ...areaDimensions,
                  length: unitsInInches ? e.target.value : e.target.value * 12,
                })
              }
            ></input>
            <span>{`Length (${unitsInInches ? '"' : "'"})`}</span>
            <span>
              <ToggleButton
                state={toggle}
                setState={setToggle}
              />
            </span>
            <span>Show Space</span>
          </div>
        </div>
        <div id='height-references'>
          <span>HEIGHT REFERENCES</span>
          <div className='height-ref-list'>
            <div className='height-ref-tile level-one'></div>
            <span>8"</span>
            <div className='height-ref-tile level-two'></div>
            <span>16"</span>
            <div className='height-ref-tile level-three'></div>
            <span>24"</span>
            <div className='height-ref-tile level-four'></div>
            <span>32"</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DimensionsReference;
