import React, { useEffect, useState } from 'react';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import { AiOutlineCloseCircle } from 'react-icons/ai';

function EditCloudinaryLinks() {
  const axiosJWT = useAxiosJWT();
  const [tables, setTables] = useState();
  const [warning, setWarning] = useState();
  const [loading, setLoading] = useState();
  const [response, setResponse] = useState();

  async function getTables() {
    axiosJWT.get(`adminPortal/getTablesWithCloudinaryLinks`).then((response) => {
      setTables(response.data);
    });
  }

  function handleClick(table) {
    setLoading(true);
    axiosJWT
      .post(`adminPortal/updateFileLinks`, {
        tableName: table.table_name,
        publicIdField: table.public_id_field,
        secureUrlField: table.secure_url_field,
      })
      .then((response) => {
        setResponse(response.data);
        setLoading();
      });
  }

  useEffect(() => {
    getTables();
  }, []);

  useEffect(() => {
    setResponse();
    setLoading();
  }, [warning]);

  return (
    <div>
      <h2>Update Cloudinary Links</h2>
      <div className='editor-portal-buttons-wrapper'>
        {tables?.map((table, index) => (
          <button
            key={index}
            className='editorButton'
            style={{
              border: ' 1px solid rgb(179, 179, 179)',
              borderRadius: '3rem',
              padding: '1rem 2rem',
              background: 'none',
              fontSize: '18px',
              fontWeight: '500',
              textTransform: 'uppercase',
              width: '350px',
              color: 'rgb(0, 67, 91)',
              cursor: 'pointer',
            }}
            onClick={() => {
              setWarning(table);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setWarning(table);
              }
            }}
          >
            {table.table_name}
          </button>
        ))}
      </div>
      {warning && (
        <div className='popup'>
          <div className='overlay'></div>
          <div
            className='popup-element'
            style={{
              width: '50rem',
              left: '30%',
              borderColor: 'white',
              borderRadius: '2rem',
              boxShadow: '1px 5px 5px grey',
              position: 'fixed',
              height: '265px',
              marginTop: '15rem',
              padding: '0px 30px',
            }}
          >
            <AiOutlineCloseCircle
              className='close-button'
              onClick={() => {
                setWarning(false);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setWarning(false);
                }
              }}
              style={{ top: '1rem', right: '1rem' }}
            />
            <h2 style={{ color: 'red', textDecorationColor: 'black' }}>WARNING</h2>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {!response && !loading ?
                <>
                  <p>
                    By clicking update, you will be erasing the entire {warning.secure_url_field}{' '}
                    field in the {warning.table_name} table and updating it with new data from
                    cloudinary. Are you sure you want to continue?
                  </p>
                  <div className='basic-flex'>
                    <button
                      className='update-images-button'
                      style={{ marginTop: '2rem', cursor: 'pointer' }}
                      onClick={() => {
                        handleClick(warning);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleClick(warning);
                        }
                      }}
                    >
                      Continue
                    </button>
                    <button
                      className='update-images-button'
                      style={{ marginTop: '2rem', cursor: 'pointer' }}
                      onClick={() => {
                        setWarning(false);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          setWarning(false);
                        }
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              : loading ?
                <p>Updating...</p>
              : <>
                  <p>{response}</p>
                  <button
                    className='update-images-button'
                    style={{ marginTop: '2rem', cursor: 'pointer' }}
                    onClick={() => {
                      setWarning(false);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        setWarning(false);
                      }
                    }}
                  >
                    Close
                  </button>
                </>
              }
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EditCloudinaryLinks;
