import { AdvancedImage } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';
import { getPage } from '@cloudinary/url-gen/actions/extract';
import { useState } from 'react';
import Popup from '../Shared/Popup';

const NewLit = ({ lit }) => {
  const [open, setOpen] = useState(false);

  const cld = new Cloudinary({
    cloud: {
      cloudName: 'da3rom333',
    },
  });
  return (
    <div className='lit-square'>
      <div className='img-div'>
        <img
          src={`https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/${lit.img_link}`}
          alt={lit.title}
        />
      </div>

      <div className='details'>
        <h4>{lit.category}</h4>
        <h2>{lit.title}</h2>
        <div className='underline'></div>
        <p>{lit.details}</p>
        <button
          onClick={() => {
            if (lit.popup_type?.toLowerCase() === 'cloudinary') {
              window.open(lit.popup_link);
            } else {
              setOpen(true);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (lit.popup_type?.toLowerCase() === 'cloudinary') {
                window.open(lit.popup_link);
              } else {
                setOpen(true);
              }
            }
          }}
        >
          See More
        </button>
      </div>
      {open ?
        <Popup
          setShow={setOpen}
          element={
            lit.popup_type === 'Issuu' ?
              <div
                style={{
                  position: 'relative',
                  height: '80%',
                  width: '80%',
                }}
              >
                <iframe
                  allow='clipboard-write'
                  sandbox='allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-forms'
                  allowFullScreen={true}
                  style={{
                    position: 'absolute',
                    border: 'none',
                    width: '100%',
                    height: '100%',
                    left: '0',
                    right: '0',
                    top: '0',
                    bottom: '0',
                  }}
                  src={lit.popup_link}
                  title={lit.details}
                ></iframe>
              </div>
            : lit.popup_type === 'Cloudinary' ?
              <div>
                <div
                  style={{
                    height: '692px',
                    overflow: 'auto',
                    display: 'flex',
                    alignItem: 'center',
                    flexDirection: 'column',
                    width: '32rem',
                  }}
                >
                  {[...Array(4)].map((_, index) => {
                    return (
                      <AdvancedImage
                        key={index}
                        cldImg={cld
                          .image(lit.popup_link)
                          .format('png')
                          .extract(getPage().byNumber(index + 1))}
                        alt={lit.title}
                      />
                    );
                  })}
                </div>
              </div>
            : ''
          }
        />
      : ''}
    </div>
  );
};

export default NewLit;
