import Axios from 'axios';
import { useEffect, useState } from 'react';
import '../../CSS/Warranty.css';
const Warranty = () => {
  const warrantyInfo =
    'https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1688653478/Website%20Assets/JPGs/Grupo_1138_m1rxx1.png';
  const banner =
    'https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1688650803/Website%20Assets/Banners/Warranty_smyrmf.jpg';
  const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;
  const [warrantyGraphics, setWarrantyGraphics] = useState([]);

  function getAboutWarranty() {
    Axios.get(`${apiAddress}/content/getAboutWarranty`)
      .then((response) => {
        setWarrantyGraphics(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    document.title = `Warranty - National Public Seating`;
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getAboutWarranty();
  }, []);

  return (
    <div className='warrantyPage'>
      <div className='banner'>
        <img
          alt='warranty-banner'
          src={banner}
        ></img>
      </div>

      <div
        style={{ marginTop: '3rem' }}
        className='inner-padding'
      >
        <div className='graphics'>
          {warrantyGraphics.map((i, index) => {
            return (
              <div
                key={index}
                className='warranty-graphic'
              >
                <img
                  src={i.img}
                  alt={i.title}
                ></img>
                <div>
                  <h1>{i.title}</h1>
                  <h2>{i.sub_title}</h2>
                </div>
              </div>
            );
          })}
        </div>
        <div className='warranty-info'>
          <div className='info'>
            <h1>National Public Seating®</h1>
            <p>
              Offers the original user a warranty against all manufacturing defects in material and
              workmanship from date of purchase for ten years*. If defective, NPS will, at its
              option, repair or replace the defective product. This warranty does not apply to
              damage resulting from misuse, abuse or neglect. Excessive swaying back and forth on
              any banquet stack chair will entirely void the warranty. Authorization for return and
              / or repairs is required by contacting your dealer. This warranty gives you specific
              rights, and you may also have other rights which vary by state.<br></br>
              <br></br> Orders are accepted under the express agreement that under no circumstances
              will seller be liable for any incidental or consequential damages, or for any other
              loss, damage or expense of any kind, including loss of profits, arising in connection
              with the order or with the use or inability to use seller's products furnished under
              the order. Seller's maximum liability shall not exceed and buyer's remedy is limited
              to either (i) repair or replacement of the defective part or product, or at seller's
              option (ii) return of the product and refund of the purchase price. Such remedy shall
              be the buyer's entire and exclusive remedy.
            </p>
            <button
              onClick={() => {
                window.open(
                  'https://res.cloudinary.com/da3rom333/image/upload/v1687975269/Product%20Assets/Literature/Terms%20and%20Conditions/NPS_Warranty_amxbp2.pdf'
                );
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  window.open(
                    'https://res.cloudinary.com/da3rom333/image/upload/v1687975269/Product%20Assets/Literature/Terms%20and%20Conditions/NPS_Warranty_amxbp2.pdf'
                  );
                }
              }}
            >
              Download Our Warranty Certificate
            </button>
          </div>
          <div>
            <img
              src={warrantyInfo}
              alt='warranty information'
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Warranty;
