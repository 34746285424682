import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, createSearchParams, useNavigate } from 'react-router-dom';

const DiscoverProductsCard = ({ imageUrl, category, itemName, link }) => {
  const [hover, setHover] = useState(false);
  const screenType = useSelector((state) => state.isMobile.value);
  const navigate = useNavigate();
  function handleClick() {
    if (category === 'Catalog' || category === 'Price List') {
      navigate({
        pathname: `/catalog`,
        search: `?${createSearchParams({
          catalog: itemName,
        })}`,
      });
    }
  }

  return (
    <div
      role='button'
      aria-label={itemName}
      tabIndex={0}
      className={`productCardsHome ${
        category === 'Catalog' || category === 'Price List' ? 'catalog-card' : ''
      }`}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onClick={handleClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleClick();
        }
      }}
    >
      <Link
        className='discover-link'
        to={
          itemName ? `/products/${link}`
          : link ?
            `/categories/${link}`
          : '/categories'
        }
      >
        <div
          className={`productCardsImage ${
            category === 'Catalog' || category === 'Price List' ? 'catalog-img' : ''
          }`}
          style={{
            backgroundImage: `url('https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/${imageUrl}')`,
          }}
          aria-label={`Check out the ${itemName}`}
        ></div>

        <h2
          className={`${
            category.length >= 25 ?
              category.length >= 30 ?
                'sixteen'
              : 'twenty-one'
            : 'twenty-four'
          }`}
        >
          {category}
        </h2>
        <h1 className={itemName?.length > 20 ? 'long' : ''}>{itemName}</h1>
        <button className={`discover-button${!screenType.isMobile && hover ? '-hover' : ''}`}>
          {category === 'Catalog' || category === 'Price List' ? 'VIEW NOW' : 'SEE MORE'}
        </button>
      </Link>
    </div>
  );
};

export default DiscoverProductsCard;
