import Axios from 'axios';
import introJs from 'intro.js';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import { useEffect } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BiSolidHelpCircle, BiSolidSelectMultiple } from 'react-icons/bi';
import { FaListAlt, FaSave } from 'react-icons/fa';
import { FaRotate } from 'react-icons/fa6';
import { IoIosWarning } from 'react-icons/io';
import { IoClose, IoDuplicate } from 'react-icons/io5';
import { MdDeleteForever, MdDeleteSweep, MdEdit } from 'react-icons/md';
import { PiWarningOctagonFill } from 'react-icons/pi';
import { TbZoomInFilled } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import EditStageBuild from '../../Components/Stage/EditStageBuild';
import ProductList from '../../Components/Stage/ProductList';
import SaveStageBuild from '../../Components/Stage/SaveStageBuild';
import SelectStageItems from '../../Components/Stage/SelectStageItems';
import StageAddAccessories from '../../Components/Stage/StageAddAccessories';
import {
  checkForBadConnections,
  checkForConnectedCorners,
  checkIfStable,
  findMidpoint,
  getAllowedDropCoordinates,
  rotateShape,
} from '../../Components/Stage/StageFunctions';
import StageGrid from '../../Components/Stage/StageGrid';
import StageSidebar from '../../Components/Stage/StageSidebar';
import ZoomBar from '../../Components/Stage/ZoomBar';
import { useStageInfo } from '../../context/StageProvider';
import '../../CSS/Onboarding.css';
import '../../CSS/StageDragDrop.css';
import '../../CSS/StagePopup.css';
import useAuth from '../../hooks/useAuth';
import { clearAndResetStateAction, updateProductInfo } from '../../ReduxSlices/productState';

const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;

function StageDragDrop() {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const dispatch = useDispatch();
  const {
    stageBuilder,
    addStage,
    product,
    optionsSelected,
    skuCompleted,
    filteredProducts,
    fullResponse,
    series,
  } = useSelector((state) => state.productState.value);

  const {
    ItemList,
    setItemList,
    buildStep,
    configure,
    setConfigure,
    stageBuild,
    setStageBuild,
    setBuildProducts,
    setUniqueIdTracker,
    hover,
    setHover,
    selected,
    setSelected,
    selectedPosition,
    setSelectedPosition,
    dragging,
    setDragging,
    allSelected,
    setAllSelected,
    notConnected,
    setNotConnected,
    unstable,
    setUnstable,
    badConnections,
    setBadConnections,
    openPopup,
    setOpenPopup,
    setSurfaceOptions,
    showProductList,
    setShowProductList,
    handleConfigureItem,
    handleAddItem,
    handleDelete,
    handleDuplicate,
    handleSelectAll,
    groupByProduct,
    showZoomBar,
    setShowZoomBar,
    zoom,
    imageRef,
    getPackagesData,
    showOnboarding,
    setShowOnboarding,
    onboardingSteps,
    setOnboardingNextStepState,
    accessoryPage,
    resetStageStates,
    notification,
  } = useStageInfo();

  async function getData() {
    await Axios.get(`${apiAddress}/products/getStageInfo`)
      .then((response) => {
        setItemList(response.data);
      })
      .catch((error) => {
        console.error('Error fetching stage data:', error);
      });
    getPackagesData();
  }

  function logEvent() {
    Axios.post(`${apiAddress}/content/logEvent`, {
      user: auth?.rep || 'N/A',
      page: '/stage-configurator',
    }).catch((error) => {
      console.error('Error adding to stage-configurator log:', error);
    });
  }

  function handleEditItem(selected, productList) {
    setStageBuild((prevStageBuild) =>
      prevStageBuild.map((i) => {
        if (i.uid === selected) {
          const item = ItemList.find((item) => product.modelNum.includes(item.modelNum));
          item.productList = productList;
          return {
            ...item,
            uid: i.uid,
            dimensions:
              i.id === item.id ?
                i.dimensions
              : getAllowedDropCoordinates(
                  stageBuild.filter((s) => s.uid !== i.uid),
                  item
                ),
          };
        } else {
          return i;
        }
      })
    );
  }
  function handleHover(item, event) {
    if (dragging?.uid !== item.uid) {
      if (notConnected.find((i) => item.uid === i) !== undefined) {
        setHover({
          text: 'This item is not connected to any other item!',
          color: 'red',
          top: event.clientY,
          left: event.clientX,
        });
      } else if (unstable.find((i) => item.uid === i) !== undefined) {
        setHover({
          text: 'This item is unstable! Connect it to a rectangle stage or a pie stage that is connected to a rectangle stage',
          color: 'red',
          top: event.clientY,
          left: event.clientX,
        });
      } else if (badConnections.find((i) => item.uid === i.uid)) {
        setHover({
          text: 'This item does not connect to an item next to it!',
          color: 'orange',
          top: event.clientY,
          left: event.clientX,
        });
      }
    }
  }

  function handleSelect(item) {
    if (allSelected) {
      setDragging('All');
      return;
    }
    setDragging(item);
    setSelected(item.uid);
    setAllSelected(false);
    //move the selected item to the last index in the array
    const itemIndex = stageBuild.findIndex((i) => i.uid === item.uid);
    if (itemIndex !== -1) {
      stageBuild.splice(itemIndex, 1);
    }
    stageBuild.push(item);
  }
  function handleMove(e) {
    //if one item is selected, move only that item. if the allSelected, move all
    if ((dragging?.uid >= 0 && selected === dragging?.uid) || (allSelected && dragging === 'All')) {
      setStageBuild((prevStageBuild) =>
        prevStageBuild.map((i) => {
          if (i.uid === dragging.uid || allSelected) {
            const newCoordinates = {
              ...i.dimensions,
              topLeft: {
                ...i.dimensions.topLeft,
                x: i.dimensions.topLeft.x + e.movementX,
                y: i.dimensions.topLeft.y + e.movementY,
              },
              topRight: {
                ...i.dimensions.topRight,
                x: i.dimensions.topRight.x + e.movementX,
                y: i.dimensions.topRight.y + e.movementY,
              },
              bottomRight: {
                ...i.dimensions.bottomRight,
                x: i.dimensions.bottomRight.x + e.movementX,
                y: i.dimensions.bottomRight.y + e.movementY,
              },
              bottomLeft: {
                ...i.dimensions.bottomLeft,
                x: i.dimensions.bottomLeft.x + e.movementX,
                y: i.dimensions.bottomLeft.y + e.movementY,
              },
            };
            if (i.type === 'rectangle') {
              newCoordinates.topMiddle = {
                ...i.dimensions.topMiddle,
                x: i.dimensions.topMiddle.x + e.movementX,
                y: i.dimensions.topMiddle.y + e.movementY,
              };
              newCoordinates.bottomMiddle = {
                ...i.dimensions.bottomMiddle,
                x: i.dimensions.bottomMiddle.x + e.movementX,
                y: i.dimensions.bottomMiddle.y + e.movementY,
              };
            }
            return { ...i, dimensions: newCoordinates };
          } else {
            return i;
          }
        })
      );
    }
    if (dragging) {
      setSelectedPosition({ top: e.clientY, left: e.clientX });
    }
  }

  function handleDrop() {
    if (dragging) {
      setDragging();
      setStageBuild((prevStageBuild) =>
        prevStageBuild.map((i) =>
          i.uid === dragging?.uid ?
            {
              ...i,
              dimensions: getAllowedDropCoordinates(stageBuild, i, selected),
            }
          : i
        )
      );
    }
  }

  function handleDeleteAll() {
    setStageBuild([]);
    setUniqueIdTracker(0);
    setSelected();
  }
  function handleRotate() {
    const item = stageBuild.find((i) => i.uid === selected);
    const midpoint = findMidpoint(item.dimensions);
    let newCoordinates = { ...item.dimensions };
    //const theta = item.type === 'rectangle' && item.rotated ? -90 * (Math.PI / 180) : 90 * (Math.PI / 180);
    const theta = item.rotated ? -90 * (Math.PI / 180) : 90 * (Math.PI / 180); //90° rotation, converted to radians, negative if already rotated
    newCoordinates = rotateShape(item, midpoint, newCoordinates, theta);
    if (item.type === 'rectangle') {
      newCoordinates.topMiddle = {
        ...item.dimensions.topMiddle,
        x: (newCoordinates.topRight.x + newCoordinates.topLeft.x) / 2,
        y: (newCoordinates.topRight.y + newCoordinates.topLeft.y) / 2,
      };
      newCoordinates.bottomMiddle = {
        ...item.dimensions.bottomMiddle,
        x: (newCoordinates.bottomRight.x + newCoordinates.bottomLeft.x) / 2,
        y: (newCoordinates.bottomRight.y + newCoordinates.bottomLeft.y) / 2,
      };
    }
    item.dimensions = newCoordinates;
    setStageBuild((prevStageBuild) =>
      prevStageBuild.map((i) =>
        i.uid === selected ?
          {
            ...i,
            dimensions: getAllowedDropCoordinates(stageBuild, item, selected),
            rotated: !item.rotated,
          }
        : i
      )
    );
  }

  function handleShowInfo() {
    const item = stageBuild.find((i) => i.uid === selected);
    handleConfigureItem(item.productList[0].productInfo.Series, item.productList[0].configOptions);
  }

  function getBadConnectionLine(uid, side) {
    const item = stageBuild.find((i) => i.uid === uid);
    if (item) {
      switch (side) {
        case 'top':
          return (
            <line
              key={`${uid}- ${side}`}
              x1={item.dimensions.topLeft.x}
              y1={item.dimensions.topLeft.y}
              x2={item.dimensions.topRight.x}
              y2={item.dimensions.topRight.y}
              style={{ stroke: 'orange', strokeWidth: 1 }}
              strokeLinecap='round'
              transform={`scale(${zoom})`}
            />
          );
        case 'Right':
          return (
            <line
              key={`${uid}- ${side}`}
              x1={item.dimensions.topRight.x}
              y1={item.dimensions.topRight.y}
              x2={item.dimensions.bottomRight.x}
              y2={item.dimensions.bottomRight.y}
              style={{ stroke: 'orange', strokeWidth: 1 }}
              strokeLinecap='round'
              transform={`scale(${zoom})`}
            />
          );
        case 'bottom':
          return (
            <line
              key={`${uid}- ${side}`}
              x1={item.dimensions.bottomLeft.x}
              y1={item.dimensions.bottomLeft.y}
              x2={item.dimensions.bottomRight.x}
              y2={item.dimensions.bottomRight.y}
              style={{ stroke: 'orange', strokeWidth: 1 }}
              strokeLinecap='round'
              transform={`scale(${zoom})`}
            />
          );
        case 'Left':
          return (
            <line
              key={`${uid}- ${side}`}
              x1={item.dimensions.topLeft.x}
              y1={item.dimensions.topLeft.y}
              x2={item.dimensions.bottomLeft.x}
              y2={item.dimensions.bottomLeft.y}
              style={{ stroke: 'orange', strokeWidth: 1 }}
              strokeLinecap='round'
              transform={`scale(${zoom})`}
            />
          );
        default:
          break;
      }
    }
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    logEvent();
  }, [auth?.rep]);

  useEffect(() => {
    if (series === 'Fixed Height Stages' || series === 'Risers') {
      setConfigure(series);
      if (optionsSelected) {
        navigate(
          {
            pathname: `/stage-configurator/${encodeURIComponent(series)}`,
            search: `?${createSearchParams(optionsSelected)}`,
          },
          { replace: true }
        );
      }
    }
  }, [stageBuilder, series]);

  //move all dimensions to center of drop area
  useEffect(() => {
    if (ItemList) {
      ItemList.forEach((item) => {
        const diff = 500 - (item.dimensions.topRight.x - item.dimensions.topLeft.x) / 2;
        Object.keys(item.dimensions).forEach((corner) => {
          item.dimensions[corner].x += diff;
          item.dimensions[corner].y += 300;
        });
      });
    }
  }, [ItemList]);

  useEffect(() => {
    if (!configure || (configure !== 'Fixed Height Stages' && configure !== 'Risers')) {
      navigate(`/stage-configurator`);
      dispatch(clearAndResetStateAction({ stageBuilder: stageBuilder }));
    }
  }, [configure]);

  useEffect(() => {
    if (configure && addStage) {
      let productList;
      if (
        product.productInfo.Series === 'Fixed Height Stages' ||
        product.productInfo.Series === 'Risers'
      ) {
        productList = [...filteredProducts];
        dispatch(
          updateProductInfo({
            optionsSelected: {
              ...optionsSelected,
              Surface: productList[0].configOptions.Surface.selectionName,
            },
          })
        );
      } else if (!skuCompleted) {
        dispatch(
          updateProductInfo({
            addStage: false,
            notification: 'Complete Sku before adding to build',
          })
        );
        return;
      }
      if (addStage === 'edit') {
        handleEditItem(selected, productList);
      } else {
        setSurfaceOptions(fullResponse.configOptions.finishes.Surface.Surface.values);
        handleAddItem(
          ItemList.find((item) => productList[0].modelNum.includes(item.modelNum)),
          productList
        );
      }
      setConfigure();
      dispatch(updateProductInfo({ addStage: false }));
    }
  }, [configure, addStage, skuCompleted]);

  useEffect(() => {
    if (stageBuild?.length > 1) {
      setNotConnected(
        stageBuild.map((item) => {
          if (checkForConnectedCorners(stageBuild, item, item.dimensions).length < 2) {
            return item.uid;
          }
        })
      );
      const badConnections = [];
      stageBuild.forEach((item) => {
        let bc = checkForBadConnections(stageBuild, item);
        //if its a triangle, don't count bottom as bad connection
        if (item.type === 'pie' && item.level === 1) {
          bc = bc.filter((side) => side !== 'bottom');
        }
        if (bc.length) {
          badConnections.push({
            uid: item.uid,
            badConnections: bc,
          });
        }
      });
      setBadConnections(badConnections);
    }
    setUnstable(
      stageBuild.map((item) => {
        if (item.type === 'pie' && !checkIfStable(stageBuild, item, false)) {
          return item.uid;
        }
      })
    );

    const groupedByProduct = groupByProduct(stageBuild);
    setBuildProducts(
      groupedByProduct.sort((a, b) => {
        if (a.modelNum < b.modelNum) {
          return -1;
        }
        if (a.modelNum > b.modelNum) {
          return 1;
        }
        return 0;
      })
    );
  }, [stageBuild]);

  useEffect(() => {
    let filteredBadConnections = [...badConnections];
    notConnected.forEach((itemUid) => {
      if (badConnections.find((i) => i.uid === itemUid)) {
        filteredBadConnections = filteredBadConnections.filter((c) => c.uid !== itemUid);
      }
    });
    setBadConnections(filteredBadConnections);
  }, [notConnected]);

  useEffect(() => {
    if (hover) {
      setTimeout(() => {
        setHover();
      }, 2500);
    }
  }, [hover]);

  useEffect(() => {
    return () => {
      resetStageStates();
      dispatch(updateProductInfo({ stageBuilder: false }));
    };
  }, []);

  return (
    <div className='stage'>
      <h4 className='title-banner stage-configurator-banner'>
        Stage Configurator
        <BiSolidHelpCircle
          className='stage-configurator-help'
          onClick={() => setShowOnboarding(true)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setShowOnboarding(true);
            }
          }}
        />
      </h4>
      <div className='stage-build'>
        {showOnboarding && onboardingSteps?.length > 0 && (
          <Steps
            enabled={true}
            steps={onboardingSteps}
            initialStep={0}
            onChange={(nextStepIndex) => {
              setOnboardingNextStepState(nextStepIndex);
            }}
            onBeforeChange={() => {
              introJs().refresh();
            }}
            onExit={() => {
              setShowOnboarding(false);
              if (onboardingSteps[0].element === '.GenerateCustom') {
                localStorage.setItem(`stage-configurator-generate-custom`, 'true');
              } else if (buildStep === 2) {
                localStorage.setItem(
                  `stage-configurator-onboarding-${buildStep}-${accessoryPage}`,
                  'true'
                );
              } else {
                localStorage.setItem(`stage-configurator-onboarding-${buildStep}`, 'true');
              }
            }}
            // onComplete={() => {
            //   if (buildStep === 1) {
            //     resetStageStates();
            //   }
            // }}
            options={{ doneLabel: 'Finish', keyboardNavigation: true }} //disableInteraction: true
          />
        )}
        <StageSidebar />
        <div ref={imageRef}>
          {hover && (
            <div
              className='stage-hover'
              style={{
                background: hover.color,
                top: hover.top,
                left: hover.left,
                zIndex: 100,
              }}
            >
              {hover.text}
            </div>
          )}
          <svg
            id='stage-svg'
            xmlns='http://www.w3.org/2000/svg'
            width='1000px'
            height='696px'
            onMouseDown={(e) => {
              if (!e.target.className.baseVal.includes('path')) {
                setSelected();
                setAllSelected(false);
              }
            }}
            onMouseMove={(e) => handleMove(e)}
            onMouseUp={handleDrop}
            onMouseLeave={handleDrop}
          >
            <g>
              <StageGrid />
              {buildStep === 1 ?
                stageBuild.map((item) => {
                  return (
                    <>
                      <path
                        d={`M ${item.dimensions?.topLeft.x} ${item.dimensions?.topLeft.y} L ${item.dimensions?.topRight.x} ${item.dimensions?.topRight.y} L ${item.dimensions?.bottomRight.x} ${item.dimensions?.bottomRight.y} L ${item.dimensions?.bottomLeft.x} ${item.dimensions?.bottomLeft.y} z`}
                        fill='var(--darkblue)'
                        fillOpacity={
                          item.level === 4 ? 1
                          : item.level === 3 ?
                            0.8
                          : item.level === 2 ?
                            0.6
                          : item.level === 1 ?
                            0.4
                          : ''
                        }
                        stroke={
                          // item.middle === 'true'
                          //   ? 'purple':
                          selected === item.uid || allSelected ? '#c0e66c'
                          : notConnected.includes(item.uid) || unstable.includes(item.uid) ?
                            'red'
                          : 'white'
                        }
                        strokeWidth='5px'
                        strokeOpacity={
                          selected === item.uid || allSelected ? '1'
                          : notConnected.includes(item.uid) || unstable.includes(item.uid) ?
                            '1'
                          : '0.5'
                        }
                        className={`draggable-${item.type} path uid-${item.uid} ${
                          item.uid === dragging?.uid ? 'dragging' : ''
                        }`}
                        id={`uid-${item.uid}`}
                        key={item.uid}
                        draggable
                        onMouseDown={(e) => handleSelect(item, e)}
                        onMouseUp={(e) => setSelectedPosition({ top: e.clientY, left: e.clientX })}
                        onMouseOver={(e) => handleHover(item, e)}
                        onMouseLeave={() => setHover()}
                        tabIndex='0'
                        onKeyDown={(e) => {
                          if (e.key === 'Delete') {
                            handleDelete(selected);
                          }
                        }}
                        transform={`scale(${zoom})`}
                      ></path>
                      {(
                        (notConnected.find((i) => i === item.uid) ||
                          unstable.find((i) => i === item.uid)) &&
                        item.uid !== dragging?.uid
                      ) ?
                        <PiWarningOctagonFill
                          x={(item.dimensions.topLeft.x + 5) * zoom}
                          y={(item.dimensions.topLeft.y + 5) * zoom}
                          color='red'
                        />
                      : badConnections.find((c) => c.uid === item.uid) && (
                          <IoIosWarning
                            x={(item.dimensions.topLeft.x + 5) * zoom}
                            y={(item.dimensions.topLeft.y + 5) * zoom}
                            color='orange'
                          />
                        )
                      }
                    </>
                  );
                })
              : buildStep === 2 ?
                <StageAddAccessories />
              : buildStep === 3 && (
                  <SelectStageItems
                    type='cart'
                    selected={[]}
                    setSelected={() => {}}
                  />
                )
              }
            </g>
            {badConnections?.map((connection) => {
              if (connection.badConnections.length) {
                return connection.badConnections.map((side) => {
                  return getBadConnectionLine(connection.uid, side);
                });
              }
            })}
          </svg>

          {stageBuild?.length > 0 && buildStep === 1 && (
            <div className='selected-options'>
              <button
                onClick={handleSelectAll}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSelectAll();
                  }
                }}
              >
                <BiSolidSelectMultiple /> Select All
              </button>
              <button
                onClick={() => {
                  setShowZoomBar(!showZoomBar);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setShowZoomBar(!showZoomBar);
                  }
                }}
              >
                <TbZoomInFilled /> Zoom
              </button>
              <button
                onClick={handleDeleteAll}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleDeleteAll();
                  }
                }}
              >
                <MdDeleteSweep /> Clear Build
              </button>
              {showZoomBar && <ZoomBar />}
            </div>
          )}
        </div>
      </div>
      {selected >= 0 && !dragging && (
        <div
          className='selected-options item-options'
          style={{
            top: selectedPosition?.top + 180,
            left: selectedPosition?.left - 500,
          }}
        >
          <button
            onClick={handleRotate}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleRotate();
              }
            }}
          >
            <FaRotate />
          </button>
          <button
            onClick={() => handleDuplicate(selected)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleDuplicate(selected);
              }
            }}
          >
            <IoDuplicate />
          </button>
          <button
            onClick={() => handleDelete(selected)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleDelete(selected);
              }
            }}
          >
            <MdDeleteForever />
          </button>
          <button
            onClick={handleShowInfo}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleShowInfo();
              }
            }}
          >
            <MdEdit />
          </button>
        </div>
      )}
      {stageBuild?.length > 0 && auth?.roles?.includes(1117) && (
        <div className='selected-options developer-prebuilt-packages-options'>
          <button
            onClick={() => {
              setOpenPopup('Save');
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setOpenPopup('Save');
              }
            }}
          >
            <FaSave /> Save
          </button>
          <button
            onClick={() => {
              setOpenPopup('Edit');
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setOpenPopup('Edit');
              }
            }}
          >
            <MdEdit /> Edit
          </button>
        </div>
      )}
      <div>
        <div
          id='view-products'
          style={{
            position: 'absolute',
            left: showProductList ? '80.5rem' : '98rem',
            bottom: '-5.1rem',
            width: showProductList ? '342px' : '64px',
          }}
        >
          {showProductList ?
            <div className='product-list-wrapper'>
              <IoClose
                onClick={() => setShowProductList(false)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setShowProductList(false);
                  }
                }}
                className='close-button'
              />
              <ProductList />
            </div>
          : buildStep < 3 && (
              <div
                role='button'
                aria-label='Open Product List'
                className='open-product-list'
                onClick={() => setShowProductList(true)}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setShowProductList(true);
                  }
                }}
              >
                <FaListAlt />
              </div>
            )
          }
        </div>
      </div>
      {openPopup && (
        <>
          <div className='modal'></div>
          <div className='stage-popup'>
            <AiOutlineCloseCircle
              onClick={() => setOpenPopup(false)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setOpenPopup(false);
                }
              }}
              className='close-button'
            />
            {openPopup === 'Save' ?
              <SaveStageBuild />
            : <EditStageBuild />}
          </div>
        </>
      )}
      {notification && <div className='notice'>{notification}</div>}
    </div>
  );
}

export default StageDragDrop;
