import { useState } from 'react';
import { Link } from 'react-router-dom';

const Categorydrop = ({ c }) => {
  const [show, setShow] = useState(false);
  const [linkPath, setLinkPath] = useState(`/categories/${encodeURIComponent(c.catName)}`);
  const [selectedCat, setSelectedCat] = useState();

  return (
    <Link
      className='cat-grid-div'
      to={linkPath}
      onMouseEnter={() => {
        setLinkPath(`/categories/${encodeURIComponent(c.catName)}`);
        setSelectedCat(c);
      }}
      state={{ selectedCat: selectedCat }}
      onMouseLeave={() => {
        setShow(false);
      }}
    >
      <div
        className='cat-div'
        onMouseEnter={() => {
          setShow(true);
        }}
      >
        {c.catName.length > 18 ? `${c.catName.substring(0, 16)} ...` : c.catName}
      </div>
      <div
        className={`subcat-div ${show ? 'show' : ''}`}
        onMouseEnter={() => {
          setShow(true);
        }}
      >
        <h2 className='cat-title'>{c.catName}</h2>
        {/* link to stage configurator */}
        {c.catName === 'Stages & Risers' && (
          <div
            className='dropdown-nav-to-stage product-page-nav-to-stage'
            onMouseEnter={() => {
              setLinkPath('/stage-configurator');
            }}
          >
            <img
              src='https://res.cloudinary.com/da3rom333/image/upload/v1723662348/Icon_Stage_Configurator_eadkxb.svg'
              alt='Stage Configuator'
            />
            Try our new <h5>STAGE CONFIGURATOR</h5>
          </div>
        )}
        {c.subCats?.map((s) => (
          <p
            className='subcat-name'
            key={s.catName}
            onMouseEnter={() => {
              setShow(true);
              if (s.subCats) {
                setLinkPath(`/categories/${encodeURIComponent(s.catName)}`);
              } else {
                setLinkPath(`/products/${encodeURIComponent(s.catName)}`);
              }
              setSelectedCat(s);
            }}
          >
            {s.catName}
          </p>
        ))}
      </div>
    </Link>
  );
};

export default Categorydrop;
