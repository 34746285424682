import { AdvancedImage, lazyload } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';
import { useDispatch, useSelector } from 'react-redux';
import { updateProductInfo } from '../../ReduxSlices/productState';

const OptionsCube = ({ option, optionType, availableOptions }) => {
  const { optionsSelected } = useSelector((state) => state.productState.value);
  const dispatch = useDispatch();
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'da3rom333',
    },
  });

  function clicked() {
    //if already selected, remove selection, else add selection
    if (
      Object.entries(optionsSelected)?.find(
        (index) => index[0] === optionType.name && index[1] === option.selectionName
      )
    ) {
      const holderObj = { ...optionsSelected };
      delete holderObj[optionType.name];
      dispatch(
        updateProductInfo({
          optionsSelected: {
            ...holderObj,
          },
        })
      );
    } else {
      dispatch(
        updateProductInfo({
          optionsSelected: {
            ...optionsSelected,
            [optionType.name]: option.selectionName,
          },
        })
      );
    }
  }

  const myImage = cld.image(option.imageLink);

  return (
    <div
      role='button'
      aria-label={option.selectionName}
      tabIndex={0}
      className={`option-cube ${
        optionsSelected[optionType.name] === option.selectionName ? 'option-cube-clicked'
        : !availableOptions.has(option.selectionName) ? 'unavailable'
        : ''
      }
       `}
      onClick={() => {
        if (availableOptions.has(option.selectionName)) {
          clicked();
        }
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          if (availableOptions.has(option.selectionName)) {
            clicked();
          }
        }
      }}
      style={{
        cursor: availableOptions.has(option.selectionName) ? 'pointer' : 'not-allowed',
      }}
    >
      <AdvancedImage
        cldImg={myImage}
        plugins={[lazyload()]}
        style={{
          borderRadius: '6px',
          cursor: availableOptions.has(option.selectionName) ? 'pointer' : 'not-allowed',
          opacity: availableOptions.has(option.selectionName) ? '1' : '.45',
        }}
        alt={option.selectionName}
      />
      <div className='h4-div'></div>
    </div>
  );
};

export default OptionsCube;
