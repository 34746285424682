import { AdvancedImage } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';
import { replaceColor } from '@cloudinary/url-gen/actions/adjust';
import { format } from '@cloudinary/url-gen/actions/delivery';
import { png } from '@cloudinary/url-gen/qualifiers/format';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const ProductCategoryImage = ({ category }) => {
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'da3rom333',
    },
  });
  const navigate = useNavigate();
  const [inHoverMode, setInHoverMode] = useState(false);

  return (
    <Link
      className='cat-grid-div'
      to={`${category.link}`}
      onClick={() => navigate(`${category.link}`)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          navigate(`${category.link}`);
        }
      }}
      onMouseEnter={() => {
        setInHoverMode(true);
      }}
      onMouseLeave={() => {
        setInHoverMode(false);
      }}
    >
      <div className='product-cat-div'>
        {inHoverMode ?
          <AdvancedImage
            cldImg={cld
              .image(category.image)
              .adjust(replaceColor('#C0E66C'))
              .delivery(format(png()))}
            alt={`${category.title}-icon`}
          />
        : <AdvancedImage
            cldImg={cld.image(category.image).delivery(format(png()))}
            alt={`${category.title}-icon`}
          />
        }
        <div
          style={{
            paddingTop: category.title.toLowerCase() === 'new products' ? '3px' : '',
          }}
        >
          {category.title}
        </div>
      </div>
    </Link>
  );
};

export default ProductCategoryImage;
