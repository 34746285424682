const loading =
  'https://res.cloudinary.com/da3rom333/image/upload/v1689011230/Website%20Assets/GIFs/LoadingProducts_qqfyyk.gif';
const FullPageLoading = () => {
  return (
    <img
      src={loading}
      style={{
        zIndex: '151',
        position: 'fixed',
        top: '0',
        left: '0',
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        opacity: '.9',
      }}
      alt='Loading Icon'
    />
  );
};

export default FullPageLoading;
