import Axios from 'axios';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import '../../CSS/ProductAddToCart.css';
import '../../CSS/QuickShipButton.css';
import '../../CSS/products.css';
import '../../CSS/MobileApp/M_Configurator.css';
import '../../CSS/MobileApp/M_Products.css';
import DetailsDisplay from '../../Components/Products/DetailsDisplay';
import NotFound from '../../Components/Products/NotFound';
import ProductTitles from '../../Components/Products/ProductTitles';
import RelatedProducts from '../../Components/Products/RelatedProducts';
import VerticalCarousel from '../../Components/Products/VerticalCarousel';
import VideoPlayer from '../../Components/Products/VideoPlayer';
import Configurations from '../../Components/Products/configurations';
import NavigateBar from '../../Components/Products/navigateBar';
import FullPageLoading from '../../Components/Shared/FullPageLoading';
import UnauthorizedPopup from '../../Components/Shared/UnauthorizedPopup';
import completeItemPrice from '../../Components/Shared/completeItemPrice';
import {
  changeProduct,
  changeTrueToVal,
  findAndSetProduct,
  resetStateAction,
  setCurrentProduct,
  setShowQuickShipIcon,
  updateProductInfo,
  validateParams,
} from '../../ReduxSlices/productState';
import { useContent } from '../../context/ContentProvider';
import useAuth from '../../hooks/useAuth';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import ClearCache from './ClearCache';
import MainProductImage from './MainProductImage';
import NoConfigDisplay from './NoConfigDisplay';
import ProductInfoButtons from './ProductInfoButtons';

function ProductsPage() {
  const axiosJWT = useAxiosJWT();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { seriesID, productID } = useParams();
  const {
    found,
    product,
    fullResponse,
    optionsSelected,
    allProducts,
    quickShipProducts,
    skuDisplayed,
    notification,
    unauthorized,
    skuCompleted,
    validUrlParams,
    showStagePromoVideo,
    stageBuilder,
    accessoriesAvailable,
    accessoriesSelected,
    accessoryPrices,
  } = useSelector((state) => state.productState.value);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const searchParamsUse = Object.fromEntries([...searchParams]);

  const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;
  const getItemPrice = completeItemPrice();
  const { chosenAccount } = useContent();

  function getQuickshipProducts() {
    Axios.post(`${apiAddress}/products/getQuickShip`, { seriesID: seriesID })
      .then((response) => {
        dispatch(updateProductInfo({ quickShipProducts: response.data }));
      })
      .catch((error) => {
        console.error('Error fetching quickship products:', error);
      });
  }

  function getNonFilterableList(configOptions) {
    var list = [];
    if (Object.keys(configOptions).length) {
      for (var i in configOptions) {
        for (var j in configOptions[i]) {
          for (var k in configOptions[i][j]) {
            if (configOptions[i][j][k] && !configOptions[i][j][k].filterable) {
              list.push(configOptions[i][j][k].name);
            }
          }
        }
      }
    }
    return list;
  }

  function getProductData(seriesID) {
    Axios.post(`${apiAddress}/products/getProductData`, {
      sku: seriesID,
    })
      .then(async (response) => {
        // Add check for error in the response here.
        // Error format will be an object that contains 2 values, see below
        // {
        // errorCode,
        // errorMessage
        //}

        //check if a series was typed into url that exists but does not have configs because it should really be getting that series's config group
        //if true, navigates to that config group instead
        if (
          response?.data?.skuList.length > 0 &&
          response?.data?.skuList[0]?.productInfo?.configSeries &&
          seriesID.toLowerCase() !==
            response?.data?.skuList[0]?.productInfo?.configSeries.toLowerCase()
        ) {
          if (!stageBuilder) {
            navigate(
              `/products/${encodeURIComponent(
                response?.data?.skuList[0]?.productInfo?.configSeries
              )}`
            );
            window.location.reload();
          } else {
            navigate(
              `/stage-configurator/${encodeURIComponent(
                response?.data?.skuList[0]?.productInfo?.configSeries
              )}`
            );
            window.location.reload();
          }
        } else {
          var list = getNonFilterableList(response.data.configOptions);

          var finishesObj = {};
          Object.keys(response.data.configOptions.finishes).map(
            (i) =>
              (finishesObj[i] =
                response.data.configOptions.finishes[i][
                  Object.keys(response.data.configOptions.finishes[i])[0]
                ])
          );

          dispatch(
            updateProductInfo({
              optionsSelected: searchParamsUse || false,
              filterExceptionList: list,
              fullResponse: response.data,
              skuSeries: response.data.seriesData?.sku_prefix,
              skuDisplayed: response.data.skuList[0].modelNum,
              configTypes: response.data.configOptions,
              finishTypes: finishesObj,
              series: response.data.seriesname,
              allProducts: response.data.skuList,
              filteredProducts: response.data.skuList,
            })
          );

          // eslint-disable-next-line no-console
          console.log(response, 279);
          if (Object.keys(response.data.configOptions.config).length === 0) {
            dispatch(
              updateProductInfo({
                noConfigs: true,
                titleSelected: false,
              })
            );
          }
          if (Object.keys(response.data.configOptions.finishes).length === 0) {
            dispatch(
              updateProductInfo({
                noFinishes: true,
              })
            );
          }
          if (!optionsSelected.length) {
            dispatch(
              setCurrentProduct({
                productID: productID,
                firstProduct: response.data.skuList[0],
                firstImage: response.data.skuList[0].cdnLinks[0]?.Image,
                fullList: response.data.skuList,
                firstRender: true,
              })
            );
          }
          if (response.data.configOptions.accessories?.Accessories?.values) {
            getAccessoryData(response.data.configOptions.accessories.Accessories.values);
          }
          dispatch(
            updateProductInfo({
              found: 'found',
            })
          );
        }
      })
      .catch((i) => {
        console.error('not found', i);
        dispatch(
          updateProductInfo({
            found: 'not found',
          })
        );
      });
  }

  // useEffect(() => {
  //   document.title = `${seriesID} - National Public Seating`;
  //   dispatch(resetStateAction());
  // }, [seriesID]);

  const getPricing = async (includeAccessories) => {
    if (
      product &&
      (Object.keys(product?.configOptions).length <= 0 || skuCompleted) &&
      (auth?.accounts
        ?.find((account) => account.last_login === 1)
        ?.AccountRoles?.some((role) => [1100, 1200, 1300].includes(role)) ||
        auth?.roles?.includes(3500))
    ) {
      dispatch(updateProductInfo({ loadingPrice: true }));
      const response = await getItemPrice(
        {
          modelNum: product.modelNum,
          quantity: product.productInfo?.Quantity,
          configOptions: product.configOptions,
          fullSku: skuDisplayed,
        },
        fullResponse?.seriesData,
        optionsSelected,
        chosenAccount
      );
      if (!response?.basePrice || response?.basePrice < 1) {
        dispatch(updateProductInfo({ customerPrice: null, loadingPrice: false }));
      }
      if (
        includeAccessories ||
        (!accessoryPrices?.length &&
          accessoriesAvailable.length > 0 &&
          accessoriesAvailable[0].product)
      ) {
        await getAccessoryPricing();
      }

      dispatch(updateProductInfo({ customerPrice: response, loadingPrice: false }));

      //  RESPONSE EXAMPLE: {
      //     "basePrice": {
      //       "itemSku": "MSTB8-MDPLCRPLSU",
      //       "listPrice": 4497,
      //       "uomId": "EA",
      //       "quantity": 1,
      //       "unitPrice": 1708.86,
      //       "customerId": "499"
      //   },
      //   "premEdgeUpcharge": 0,
      //   "laminatesUpcharge": {
      //       "itemSku": "MTPREMLAM",
      //       "listPrice": 274,
      //       "uomId": "EA",
      //       "quantity": 1,
      //       "unitPrice": 104.12,
      //       "customerId": "499"
      //   }
      // }
    }
  };

  async function getAccessoryData(accessories) {
    const accessoriesData = [];
    for (const accessory of accessories) {
      let accessoryData = { ...accessory };
      if (accessoriesAvailable.find((a) => a.name === accessory.name)) {
        accessoryData = accessoriesAvailable.find((a) => a.name === accessory.name);
      } else {
        await axiosJWT
          .post(`${apiAddress}/products/getProductData`, {
            sku: accessory.series,
          })
          .then((response) => {
            accessoryData.product = response.data;
          })
          .catch((error) => {
            console.error('error getting accessory data', error);
          });
      }
      accessoriesData.push(accessoryData);
    }
    dispatch(updateProductInfo({ accessoriesAvailable: accessoriesData }));
  }

  async function getAccessoryPricing() {
    const accessoryUniqueList = [];
    const accessories = accessoriesAvailable;
    const accessoriesData = [];
    for (const accessory of accessories) {
      const accessoryData = {
        ...accessory,
        accessorySkus: [],
        qty: accessoriesSelected.find((a) => a.name === accessory.name)?.qty || 1,
      };
      for (const sku of accessory.accessorySkus) {
        const modelNum = sku.modelNum;
        const skuData = { ...sku };
        if (!accessoryUniqueList.find((i) => i.modelNum === modelNum)) {
          const options = {};
          Object.keys(
            accessory.product.skuList.find((sku) => sku.modelNum === modelNum).configOptions
          ).forEach(
            (key) =>
              (options[key] = accessory.product.skuList.find(
                (sku) => sku.modelNum === modelNum
              ).configOptions[key].selectionName)
          );
          const priceResponse = await getItemPrice(
            {
              modelNum: modelNum,
              quantity: accessory.qty,
              configOptions: {},
              fullSku: modelNum,
            },
            accessory.product.seriesData,
            options,
            chosenAccount
          );
          accessoryUniqueList.push({
            modelNum: modelNum,
            price: priceResponse,
          });
        }
        skuData.price = accessoryUniqueList.find((i) => i.modelNum === modelNum).price;
        accessoryData.accessorySkus.push(skuData);
      }
      accessoriesData.push(accessoryData);
    }
    dispatch(
      updateProductInfo({
        accessoryPrices: accessoryUniqueList,
      })
    );
  }
  // useEffect(() => {
  //   document.title = `${seriesID} - National Public Seating`;
  //   dispatch(resetStateAction());
  // }, [seriesID]);

  useEffect(() => {
    getQuickshipProducts();
    getProductData(seriesID);
  }, []);

  useEffect(() => {
    dispatch(validateParams({ searchParamsUse }));
  }, [searchParams]);

  useEffect(() => {
    dispatch(changeProduct());
  }, [productID]);

  useEffect(() => {
    document.title = `${seriesID} - National Public Seating`;
    if (fullResponse) {
      if (fullResponse.seriesname !== seriesID && fullResponse.seriesname !== '') {
        window.location.reload();
      }
    }
  }, [seriesID]);

  useEffect(() => {
    if (!stageBuilder) {
      navigate(
        {
          pathname: `/products/${encodeURIComponent(seriesID)}`,
          search: `?${createSearchParams(optionsSelected)}`,
        },
        { replace: true }
      );
    } else {
      navigate(
        {
          pathname: `/stage-configurator/${encodeURIComponent(seriesID)}`,
          search: `?${createSearchParams(optionsSelected)}`,
        },
        { replace: true }
      );
    }
    //change true string to true variable for add ons
    dispatch(changeTrueToVal());
  }, [optionsSelected]);

  useEffect(() => {
    if (Object.keys(optionsSelected) && validUrlParams) {
      dispatch(findAndSetProduct({ productID: productID }));
    }
  }, [optionsSelected, allProducts, validUrlParams]);

  useEffect(() => {
    dispatch(setShowQuickShipIcon());
  }, [skuDisplayed, quickShipProducts]);

  useEffect(() => {
    dispatch(updateProductInfo({ openAddToCart: false }));
    if (
      (product?.productInfo?.Series === 'Fixed Height Stages' ||
        product?.productInfo?.Series === 'Multi-Level Stages' ||
        product?.productInfo?.Series === 'Risers' ||
        product?.productInfo?.Series === 'Multi-Level Risers' ||
        product?.productInfo?.Series === 'Stage Packages' ||
        product?.productInfo?.Series === 'Multi-Level Stage Packages') &&
      !stageBuilder &&
      !localStorage.getItem(`${product.productInfo.Series}-promo-video`)
    ) {
      localStorage.setItem(`${product.productInfo.Series}-promo-video`, true);
      dispatch(updateProductInfo({ showStagePromoVideo: true }));
    }
  }, [product]);

  useEffect(() => {
    if (accessoriesAvailable.length > 0 && accessoriesAvailable[0].product) {
      getPricing('includeAccessories');
    } else {
      getPricing();
    }
  }, [auth, chosenAccount, accessoriesAvailable]);

  useEffect(() => {
    getPricing();
  }, [product, skuDisplayed]);

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        dispatch(updateProductInfo({ notification: '' }));
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [notification]);

  useEffect(() => {
    // reset the product state to initial values when component unmounts
    return () => {
      if (!stageBuilder) {
        dispatch(resetStateAction());
      }
    };
  }, [dispatch]);
  return (
    <div>
      {found === 'found' ?
        <>
          {unauthorized ?
            <UnauthorizedPopup popupParent={product} />
          : ''}
          {showStagePromoVideo && (
            <VideoPlayer
              videoSrc='https://res.cloudinary.com/da3rom333/video/upload/v1724074360/Website%20Assets/Stage%20Configurator/Stage_Configurator_Video_w8rzta.mp4'
              setShow={() => {
                dispatch(updateProductInfo({ showStagePromoVideo: false }));
              }}
              autoPlay={true}
              actionButton={{
                title: 'Go To Stage Configurator',
                action: () => {
                  window.open('/stage-configurator');
                },
              }}
            />
          )}
          <NavigateBar
            series={seriesID}
            isProductPage={true}
          />
          {notification ?
            <div className='notice product-notice'>{notification}</div>
          : ''}
          {[1984, 1117].some((value) => auth?.roles?.includes(value)) && (
            <ClearCache seriesID={seriesID} />
          )}

          <div className='grid-container-products inner-padding'>
            <VerticalCarousel defaultNumOfDisplay={5} />
            <MainProductImage />
            <ProductInfoButtons seriesID={seriesID} />
            <ProductTitles seriesID={seriesID}></ProductTitles>
            <>
              {
                allProducts.length > 1 ?
                  <div className='config-grid-contianer'>
                    <Configurations />
                  </div>
                  //for products with no configurations
                : <NoConfigDisplay />
              }
              <div className='product-slider-underline'></div>
            </>
            <div className='product-details-div'>
              <DetailsDisplay />
            </div>
          </div>
          <div className='details'></div>
          {!stageBuilder && <RelatedProducts />}
        </>
      : found === 'not found' ?
        <NotFound title={'product'} />
      : <FullPageLoading />}
    </div>
  );
}

export default ProductsPage;
