/* eslint-disable no-console */
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetStateAction } from '../../ReduxSlices/productState';

function ProductTitles({ seriesID }) {
  const {
    fullResponse,
    series,
    product,
    allProducts,
    showQuickshipIcon,
    quickShipProducts,
    stageBuilder,
  } = useSelector((state) => state.productState.value);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div className='product-titles'>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/*eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <h2
          onClick={() => {
            console.log(fullResponse);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              console.log(fullResponse);
            }
          }}
          style={{ marginRight: '8rem' }}
        >
          {series}
        </h2>
      </div>
      <div className='underline'></div> {/* Move the underline outside of the flex container */}
      <div
        className='product-desc-div'
        id='productDescDiv'
      >
        {product?.productInfo?.ShortDescription}
        {product?.productInfo?.DisclaimerText && <span>*</span>}
        {product?.productInfo?.DisclaimerText && (
          <div
            style={{
              color: 'red',
              font: "italic 400 11px / 1.5 'Poppins', Helvetica, sans-serif",
            }}
          >
            *{product?.productInfo?.DisclaimerText}
          </div>
        )}
      </div>
      {allProducts.length > 1 && (
        <div className='leadtime'>
          <img
            src='https://res.cloudinary.com/da3rom333/image/upload/v1693493772/Website%20Assets/All%20Icons/Clock_nj3c2n.svg'
            alt='Clock Icon'
          />
          <h3>Lead Time: {showQuickshipIcon ? '7 days' : product?.productInfo?.LeadTime}</h3>
          {quickShipProducts?.length ?
            <div
              role='button'
              aria-label='See Quick Ship Options'
              tabIndex={0}
              onClick={() => {
                navigate(`/quickship/${seriesID}`);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  navigate(`/quickship/${seriesID}`);
                }
              }}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <img
                src='https://res.cloudinary.com/da3rom333/image/upload/v1693323005/Icons/Certifications/QuickShip_Truck_pz9grz.svg'
                alt='Quick Ship Icon'
              />
              <h4>See Quick Ship Options</h4>
            </div>
          : ''}
          {/* link to stage configurator */}
          {(product?.productInfo?.Series === 'Fixed Height Stages' ||
            product?.productInfo?.Series === 'Multi-Level Stages' ||
            product?.productInfo?.Series === 'Risers' ||
            product?.productInfo?.Series === 'Multi-Level Risers' ||
            product?.productInfo?.Series === 'Stage Packages' ||
            product?.productInfo?.Series === 'Multi-Level Stage Packages') &&
            !stageBuilder && (
              <div
                className='product-page-nav-to-stage'
                role='button'
                aria-label='Start customizing with our new stage configurator'
                tabIndex={0}
                onClick={() => {
                  dispatch(resetStateAction());
                  navigate('/stage-configurator');
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    dispatch(resetStateAction());
                    navigate('/stage-configurator');
                  }
                }}
              >
                <img
                  src='https://res.cloudinary.com/da3rom333/image/upload/v1723662348/Icon_Stage_Configurator_eadkxb.svg'
                  alt='Stage Configurator'
                />
                Start customizing with our new <h5>STAGE CONFIGURATOR</h5>
              </div>
            )}
        </div>
      )}
    </div>
  );
}

export default ProductTitles;
