import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AboutUsSection from '../../Components/HomePage/AboutUsSection';
import CalculateShipping from '../../Components/HomePage/CalculateShipping';
import HomeCarousel from '../../Components/HomePage/Carousel';
import DiscoverProducts from '../../Components/HomePage/DiscoverProducts';
import EnvironmentsSlider from '../../Components/HomePage/EnvironmentsSlider';
import '../../CSS/Home.css';
import '../../CSS/MobileApp/M_Home.css';
import useAxiosJWT from '../../hooks/useAxiosJWT';

const Home = () => {
  useEffect(() => {
    document.title = 'National Public Seating';
  }, []);
  const navigate = useNavigate();
  const mobileScreen = useSelector((state) => state.isMobile.value);
  const [images, setImages] = useState();
  const [catalogImage, setCatalogImage] = useState();
  const axiosJWT = useAxiosJWT();

  useEffect(() => {
    axiosJWT.post(`content/getCarouselBanners`, { page: 'home' }).then((response) => {
      let filteredData;
      if (mobileScreen.isMobile) {
        filteredData = response.data.filter((i) => i.screen_type?.toLowerCase() === 'mobile');
      } else {
        filteredData = response.data.filter((i) => i.screen_type?.toLowerCase() === 'desktop');
      }
      setImages(filteredData?.filter((i) => i.carousel_type?.toLowerCase() === 'main'));
      setCatalogImage(
        response?.data?.filter((i) => {
          return i.carousel_type.toLowerCase() === 'catalog';
        })[0].image
      );
    });
  }, []);

  return (
    <div>
      <div className='inner-padding'>
        <HomeCarousel images={images} />
        <AboutUsSection />
        <DiscoverProducts />
        <CalculateShipping />
        <EnvironmentsSlider />
        <div
          className='catalog-bar'
          role='button'
          aria-label='View this years catalog'
          tabIndex={0}
          onClick={() => {
            navigate(`/Catalog`);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              navigate(`/Catalog`);
            }
          }}
        >
          <img
            src={catalogImage}
            alt='Catalog'
          ></img>
        </div>
      </div>
    </div>
  );
};

export default Home;
