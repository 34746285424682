import { useEffect, useState } from 'react';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { useSwipeable } from 'react-swipeable';
import '../../CSS/ImageSlider.css';
import '../../CSS/MobileApp/M_ImageSlider.css';

// takes an array of images or image components ie image tiles and creates a sliding carousel with button
const ImageSlider = ({
  images,
  numOfDisplay,
  scrollOnChange = false,
  imageClickHandler = false,
  intervalMove = false,
}) => {
  const mobileScreen = useSelector((state) => state.isMobile.value.isMobile);
  const [swiperIndex, setSwiperIndex] = useState(1);
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      handleSwipe('left');
    },
    onSwipedRight: () => {
      handleSwipe('right');
    },
  });
  const handleSwipe = (direction) => {
    if (direction === 'left') {
      setSwiperIndex(swiperIndex + 1);
      addIndex();
    } else if (direction === 'right') {
      setSwiperIndex(swiperIndex - 1);
      removeIndex();
    }
  };

  const [index, setIndex] = useState([]);

  function resetIndex() {
    var holderArray = [];
    for (let i = 0; i < numOfDisplay; i++) {
      holderArray.push(i);
    }
    setIndex(holderArray);
  }

  function addIndex() {
    var holderArray = [];
    if (index[numOfDisplay - 1] === images.length - 1) {
      for (var i = 1; i < numOfDisplay; i++) {
        holderArray.push(index[i]);
      }
      holderArray.push(0);
      setIndex(holderArray);
    } else {
      for (var i = 1; i < numOfDisplay; i++) {
        holderArray.push(index[i]);
      }
      holderArray.push(index[numOfDisplay - 1] + 1);
      setIndex(holderArray);
    }
  }

  function removeIndex() {
    const holderArray = [];
    if (index[0] === 0) {
      holderArray.push(images.length - 1);
      for (var i = 0; i < numOfDisplay - 1; i++) {
        holderArray.push(index[i]);
      }
      setIndex(holderArray);
    } else {
      holderArray.push(index[0] - 1);
      for (var i = 0; i < numOfDisplay - 1; i++) {
        holderArray.push(index[i]);
      }
      setIndex(holderArray);
    }
  }

  function checkIndex(key) {
    for (let i = 0; i < index.length; i++) {
      if (index.includes(parseInt(key))) {
        return `index${index.indexOf(parseInt(key))}`;
      } else if (parseInt(key) > index[numOfDisplay - 1]) {
        return 'showNew';
      } else if (parseInt(key) < index[0]) {
        return 'hideOld';
      }
    }
  }

  useEffect(() => {
    resetIndex();
  }, [images.length]);

  //change view if the image displayed changes and if out of sight - for veritcal carousel on products page
  useEffect(() => {
    let counter = index[numOfDisplay - 1];
    if (scrollOnChange === 0) {
      resetIndex();
    }
    while (scrollOnChange > counter) {
      addIndex();
      counter++;
    }
  }, [scrollOnChange]);

  useEffect(() => {
    if (intervalMove) {
      const timer = setTimeout(() => {
        addIndex();
      }, intervalMove);
      return () => clearTimeout(timer);
    }
  }, [index]);

  return (
    <div>
      <div className='slider-outer-div'>
        {numOfDisplay < images.length ?
          <button
            className='sliderButton swiper-button-prev'
            aria-label='Back'
            id='leftSliderButton'
            onClick={removeIndex}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                removeIndex();
              }
            }}
          >
            <IoChevronBack />
          </button>
        : ''}
        {mobileScreen ?
          <div
            className='swiper'
            {...handlers}
          >
            <div className='swiper-wrapper slider-image-div'>
              {images.map((i, index) => (
                <div
                  role='button'
                  aria-label={i}
                  tabIndex={0}
                  key={index}
                  className={`swiper-slide ${checkIndex(i.key)}`}
                  onClick={() => {
                    if (imageClickHandler) {
                      imageClickHandler(i);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      if (imageClickHandler) {
                        imageClickHandler(i);
                      }
                    }
                  }}
                >
                  {i}
                </div>
              ))}
            </div>
          </div>
        : <div className='slider-image-div'>
            {images.map((i, index) => (
              <div
                role='button'
                aria-label={i}
                tabIndex={0}
                key={index}
                className={checkIndex(i.key)}
                onClick={() => {
                  if (imageClickHandler) {
                    imageClickHandler(i);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    if (imageClickHandler) {
                      imageClickHandler(i);
                    }
                  }
                }}
              >
                {i}
              </div>
            ))}
          </div>
        }
        {numOfDisplay < images.length ?
          <button
            className='sliderButton swiper-button-next'
            id='rightSliderButton'
            aria-label='Forward'
            onClick={addIndex}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                addIndex();
              }
            }}
          >
            <IoChevronForward />
          </button>
        : ''}
      </div>
    </div>
  );
};

export default ImageSlider;
