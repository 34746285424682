import { AdvancedImage } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';
import { scale } from '@cloudinary/url-gen/actions/resize';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../CSS/Patents.css';
import decodeCloudinary from '../../hooks/decodeCloudinary';
import useAxiosJWT from '../../hooks/useAxiosJWT';
const banner =
  'https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1688650800/Website%20Assets/Banners/Patents_rimr4f.jpg';

const Patents = () => {
  useEffect(() => {
    document.title = `Patents - National Public Seating`;
    window.scrollTo(0, 0);
  });
  const navigate = useNavigate();
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'da3rom333',
    },
  });

  const [patents, setPatents] = useState();
  const [patentPending, setPatentPending] = useState();
  const axiosJWT = useAxiosJWT();
  function getData() {
    axiosJWT
      .get('content/getPatents')
      .then((response) => {
        setPatents(
          response.data.patents.filter((i) => {
            return i?.type?.toLowerCase() === 'patent';
          })
        );
        setPatentPending(
          response.data.patents.filter((i) => {
            return i?.type?.toLowerCase() === 'patent pending';
          })
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    getData();
  }, []);
  const bannerText =
    'NPS Public Furniture Corp. is marking the products identified on this website pursuant to 35 U.S.C. §287(a) by associating each identified product with one or more patents that cover that product.';

  const decodeCloudinaryURL = decodeCloudinary();

  return (
    <div className='patent-page'>
      <div className='patents-banner'>
        <div className='banner'>
          <img
            src={banner}
            alt='NPS Patents Banner'
          ></img>
          <div className='banner-text'>{bannerText}</div>
        </div>
      </div>
      <h2>Patent</h2>
      <div className='underline'></div>
      <div className='patents inner-padding'>
        {patents?.map((i, index) => {
          return (
            <div
              role='button'
              aria-label={i.title}
              tabIndex={0}
              key={index}
              className='patent-tile'
              onClick={() => {
                navigate(i.link);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  navigate(i.link);
                }
              }}
              style={{ cursor: 'pointer' }}
            >
              <div className='patent-img'>
                <AdvancedImage
                  cldImg={cld.image(decodeCloudinaryURL(i.img)).resize(scale().width(200))}
                  alt={`${i.title}`}
                />
              </div>
              <div>
                <h1>Patented</h1>
                <h2>{i.title}</h2>
                <h4>Patent ID</h4>
                <h3>{i.patentNum}</h3>
              </div>
            </div>
          );
        })}
      </div>
      <h2 style={{ marginTop: '4rem' }}>Patent Pending</h2>
      <div className='underline'></div>
      <div className='patents inner-padding'>
        {patentPending?.map((i, index) => {
          return (
            <div
              role='button'
              aria-label={i.title}
              tabIndex={0}
              key={index}
              className='patent-tile pending'
              onClick={() => {
                navigate(i.link);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  navigate(i.link);
                }
              }}
              style={{ cursor: 'pointer' }}
            >
              <div className='patent-img'>
                <AdvancedImage
                  cldImg={cld.image(decodeCloudinaryURL(i.img)).resize(scale().width(200))}
                  alt={i.title}
                />
              </div>
              <div>
                <h1>Patent Pending</h1>
                <h2>{i.title}</h2>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Patents;
