import Axios from 'axios';
import { useEffect, useState } from 'react';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import '../../CSS/DesignServices.css';
import Inspire from '../../Components/Dealer Portal/Inspire';
import Partners from '../../Components/Dealer Portal/Partners';
const DesignServices = () => {
  const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;
  const [aboutDesign, setAboutDesign] = useState([]);
  const banner =
    'https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1688650796/Website%20Assets/Banners/DesignServices_px4s4p.jpg';

  function getAboutDesign() {
    Axios.get(`${apiAddress}/content/getAboutDesign`)
      .then((response) => {
        setAboutDesign(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    document.title = `Design Services - National Public Seating`;
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    getAboutDesign();
  }, []);

  return (
    <div className='designServices'>
      <div className='banner'>
        <img
          src={banner}
          alt='Design Services Banner'
        />
      </div>
      <ReactCompareSlider
        className='servicesSlider'
        itemOne={
          <ReactCompareSliderImage
            src='https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1689553865/Website%20Assets/JPGs/design-partners-2_kb4gbm.jpg'
            alt='Image one'
          />
        }
        itemTwo={
          <ReactCompareSliderImage
            src='https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1689553865/Website%20Assets/JPGs/design-partners-1_uqp9m6.jpg'
            alt='Image two'
          />
        }
      />
      <div style={{ height: '911px' }}></div>
      <div className='ourService'>
        <h2>Our Service Process</h2>
        <div className='underline'></div>
        <div className='steps'>
          {aboutDesign.map((i, index) => {
            return (
              <div
                key={index}
                className='processStep'
              >
                <div className='img-div'>
                  <img
                    src={i.img}
                    alt={i.title}
                  />
                </div>
                <h1>{index + 1}.</h1>
                <h3>{i.title}</h3>
              </div>
            );
          })}
        </div>
      </div>
      <Inspire />
      <Partners />
      <div className='spaceRefresh'>
        <h1>Looking for a full space refresh?</h1>
        <h3>
          Our Space Planning & Design Team can build your ideal space, taking into account the needs
          of the environment and the number of people that fill the place.
        </h3>
        <h4>CONTACT YOUR ACCOUNT MANAGER FOR MORE DETAILS</h4>
        <button
          onClick={() => {
            window.open('/dealer-locator');
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              window.open('/dealer-locator');
            }
          }}
        >
          Find a dealer
        </button>
      </div>
    </div>
  );
};

export default DesignServices;
