import axios from 'axios';
import { useEffect, useState } from 'react';
import { FaCheckCircle, FaEye, FaEyeSlash, FaRegCheckCircle } from 'react-icons/fa';
import { HiInformationCircle } from 'react-icons/hi';
import { RiErrorWarningFill, RiLock2Fill } from 'react-icons/ri';
import { useNavigate, useParams } from 'react-router-dom';
import '../../CSS/DealerLogin.css';
const ResetPassword = () => {
  const { resetID } = useParams();
  const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

  const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;
  const RESET_PASSWORD_URL = '/auth/resetPass';
  const navigate = useNavigate();
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);
  const [infoHover, setInfoHover] = useState(false);
  const [password, setPassword] = useState('');
  const [matchPwd, setMatchPwd] = useState('');
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [toggleEye, setToggleEye] = useState(false);

  useEffect(() => {
    if (errMsg) {
      const timer = setTimeout(() => {
        setErrMsg(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [errMsg]);

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(password));
    setValidMatch(password === matchPwd && matchPwd !== '');
    // setErrMsg("");
  }, [password, matchPwd]);

  function handleSubmit(e) {
    e.preventDefault();
    // if button enabled with JS hack
    // const v1 = PWD_REGEX.test(password);
    if (validPwd && validMatch) {
      axios
        .post(
          `${apiAddress}${RESET_PASSWORD_URL}`,
          JSON.stringify({ password: password, token: resetID }),
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          }
        )
        .then(() => {
          setPassword('');
          setMatchPwd('');
          setSuccess(true);
        })
        .catch((err) => {
          if (!err?.response) {
            setErrMsg('No Server Response');
          } else {
            setErrMsg(err.response.data.error);
          }
        });
    }
  }

  return (
    <div className='request-pass-div'>
      {/* <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
        {errMsg}
      </p> */}
      {!success ?
        <div style={{ position: 'relative' }}>
          <h1>Reset Password</h1>
          <div className='underline'></div>
          <form className='login-form'>
            <div
              className={`login-input-holder ${
                pwdFocus && !validPwd ? 'error-input'
                : validPwd ? 'valid-input'
                : ''
              }`}
            >
              <div className={`dealer-icon ${validPwd ? 'valid-icon' : ''}`}>
                <RiLock2Fill />
              </div>
              <input
                type={toggleEye ? 'input' : 'password'}
                name='password'
                placeholder='Password'
                id='password'
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                onFocus={() => {
                  setPwdFocus(true);
                }}
                required
              ></input>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '5px',
                }}
              >
                {!toggleEye ?
                  <FaEye
                    className='password-eye'
                    onClick={() => setToggleEye(true)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        setToggleEye(true);
                      }
                    }}
                  />
                : <FaEyeSlash
                    className='password-eye'
                    onClick={() => setToggleEye(false)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        setToggleEye(false);
                      }
                    }}
                  />
                }
                {validPwd ?
                  <div className='dealer-msg valid'>
                    <FaCheckCircle />
                  </div>
                : pwdFocus ?
                  <div className='dealer-msg error'>
                    <RiErrorWarningFill />
                  </div>
                : <></>}
              </div>
            </div>
            <div
              className='error-msg-text'
              style={{
                display: 'flex',
                alignItems: 'center',
                alignSelf: 'flex-start',
                // marginLeft: "15rem",
              }}
            >
              {pwdFocus && !validPwd ? 'Invalid password.' : ''}
              {pwdFocus && !validPwd ?
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: '#ACACAC',
                    // marginLeft: "5px",
                  }}
                  className='password-info-svg'
                >
                  <HiInformationCircle
                    onMouseEnter={() => {
                      setInfoHover(true);
                    }}
                    onMouseLeave={() => {
                      setInfoHover(false);
                    }}
                    style={{ marginLeft: '2px' }}
                  />
                  {infoHover ?
                    <div className='password-info-popup'>
                      - At least 8 characters <br></br>- A mixture of both uppercase and lowercase
                      letters. <br></br>- A mixture of letters and numbers. <br></br>- Inclusion of
                      at least one special character, e.g., ! @ # ?
                    </div>
                  : ''}
                </div>
              : ''}
            </div>
            <div
              className={`login-input-holder ${
                matchFocus && !validMatch ? 'error-input'
                : validMatch ? 'valid-input'
                : ''
              }`}
            >
              <div className={`dealer-icon ${validMatch ? 'valid-icon' : ''}`}>
                <RiLock2Fill />
              </div>
              <input
                type={toggleEye ? 'input' : 'password'}
                name='confirmPassword'
                placeholder='Confirm Password'
                id='confirmPassword'
                value={matchPwd}
                onChange={(e) => setMatchPwd(e.target.value)}
                required
                onFocus={() => setMatchFocus(true)}
                // onBlur={() => setMatchFocus(false)}
              ></input>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '5px',
                }}
              >
                {!toggleEye ?
                  <FaEye
                    className='password-eye'
                    onClick={() => setToggleEye(true)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        setToggleEye(true);
                      }
                    }}
                  />
                : <FaEyeSlash
                    className='password-eye'
                    onClick={() => setToggleEye(false)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        setToggleEye(false);
                      }
                    }}
                  />
                }
                {validMatch ?
                  <div className='dealer-msg valid'>
                    <FaCheckCircle />
                  </div>
                : matchFocus ?
                  <div className='dealer-msg error'>
                    <RiErrorWarningFill />
                  </div>
                : <></>}
              </div>
            </div>
            <div
              className='error-msg-text'
              style={{
                alignSelf: 'flex-start',
                // marginLeft: "15rem"
              }}
            >
              {matchFocus && !validMatch ? 'Passwords do not match.' : ''}
            </div>
            {errMsg ?
              <div
                className='error-msg-text'
                style={{ position: 'absolute', bottom: '7.25rem' }}
              >
                {errMsg}
              </div>
            : <></>}

            <button
              className={`${validPwd && validMatch ? '' : 'invalid'} submit`}
              onClick={validPwd && validMatch ? handleSubmit : null}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  validPwd && validMatch ? handleSubmit : null;
                }
              }}
            >
              Reset Password
            </button>
          </form>
        </div>
      : <div className='confirmation-div'>
          <FaRegCheckCircle />
          <div
            className='confirmation-msg'
            style={{ textAlign: 'center' }}
          >
            Your password has been reset!
          </div>
          <button
            className='submit'
            onClick={() => {
              navigate('/');
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                navigate('/');
              }
            }}
          >
            Go Home
          </button>
        </div>
      }
    </div>
  );
};

export default ResetPassword;
