import { AdvancedImage } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';
import { scale } from '@cloudinary/url-gen/actions/resize';
import decodeCloudinary from '../../hooks/decodeCloudinary';

const EnvImage = ({ i, clickHandler, filter }) => {
  const decodeCloudinaryURL = decodeCloudinary();
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'da3rom333',
    },
  });

  return (
    <div
      role='button'
      aria-label={i.category}
      tabIndex={0}
      className='envImage'
      onClick={() => {
        clickHandler(i);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          clickHandler(i);
        }
      }}
    >
      <div className='container'>
        <div className='child'>
          <AdvancedImage
            cldImg={cld.image(decodeCloudinaryURL(i.image)).resize(scale().width(800))}
            alt={i.category[0]}
          />
        </div>
        <span className='envImage-text'>
          {filter !== 'all' ?
            i.category.find((i) => i.toLowerCase() === filter.toLowerCase())
          : i.category[0]}
        </span>
      </div>
    </div>
  );
};

export default EnvImage;
