import { useState } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import '../../CSS/MediaNavButtons.css';
import '../../CSS/MobileApp/M_MediaNavButtons.css';

const media =
  'https://res.cloudinary.com/da3rom333/image/upload/v1688653586/Website%20Assets/SVGs/Media_ovolmw.svg';
const catalog =
  'https://res.cloudinary.com/da3rom333/image/upload/v1688653582/Website%20Assets/SVGs/MC_Digital_Catalogs_t70spe.svg';
const videosHover =
  'https://res.cloudinary.com/da3rom333/image/upload/v1688653586/Website%20Assets/SVGs/MC_Promo_Videos_P_zdktor.svg';
const mediaHover =
  'https://res.cloudinary.com/da3rom333/image/upload/v1688653585/Website%20Assets/SVGs/MC_Media_Downloads_P_yw7jv9.svg';
const brochuresHover =
  'https://res.cloudinary.com/da3rom333/image/upload/v1688653580/Website%20Assets/SVGs/MC_Brochures_P_a5j4yz.svg';
const catalogHover =
  'https://res.cloudinary.com/da3rom333/image/upload/v1688653584/Website%20Assets/SVGs/MC_Digital_Catalogs_P_p3eeqc.svg';
const videos =
  'https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1688653566/Website%20Assets/SVGs/Videos_cgwtzf.svg';
const brochures =
  'https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1688653569/Website%20Assets/SVGs/Brochures_ihbs9w.svg';

const MediaNavButtons = ({ current }) => {
  const isMobile = useSelector((state) => state.isMobile.value.isMobile);
  let mediaButtons = [
    {
      title: 'Media Downloads',
      link: '/media',
      img: media,
      imgHover: mediaHover,
    },
    {
      title: 'Digital Catalogs',
      link: '/catalog',
      img: catalog,
      imgHover: catalogHover,
    },
    {
      title: 'Brochures',
      link: '/brochures',
      img: brochures,
      imgHover: brochuresHover,
    },
    {
      title: 'Videos',
      link: '/videos',
      img: videos,
      imgHover: videosHover,
    },
  ];
  if (!isMobile) {
    mediaButtons = mediaButtons.filter((i) => {
      return i.title !== current;
    });
  }

  const [hover, setHover] = useState();

  const navigate = useNavigate();
  return (
    <div className='mediaNavButtons inner-padding'>
      {mediaButtons.map((i) => {
        return (
          <button
            className={isMobile && i.title === current ? 'current-media-button' : ''}
            onClick={() => {
              navigate(`${i.link}`);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                navigate(`${i.link}`);
              }
            }}
            onMouseEnter={() => {
              setHover(i.title);
            }}
            onMouseLeave={() => {
              setHover();
            }}
            key={i.link}
          >
            <div
              className='media-nav-button'
              style={{ alignItems: 'center', display: 'flex' }}
            >
              <img
                src={hover === i.title || (isMobile && i.title !== current) ? i.imgHover : i.img}
                alt={i.title}
              />
              {i.title}
            </div>
            {!isMobile && <IoIosArrowForward />}
          </button>
        );
      })}
    </div>
  );
};

export default MediaNavButtons;
