import { AdvancedImage } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';
import { scale } from '@cloudinary/url-gen/actions/resize';
import Axios from 'axios';
import { useEffect, useState } from 'react';
import { LuBox } from 'react-icons/lu';
import { useDispatch, useSelector } from 'react-redux';
import '../../CSS/MobileApp/M_Products.css';
import decodeCloudinary from '../../hooks/decodeCloudinary';
import { updateProductInfo } from '../../ReduxSlices/productState';
import ViewAll from './ViewAll';

import '../../CSS/MobileApp/M_Configura3d.css';
import '../../CSS/MobileApp/M_HorizontalCarousel.css';
import '../../CSS/VerticalCarousel.css';

const VerticalCarousel = ({ defaultNumOfDisplay }) => {
  const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;
  const decodeCloudinaryURL = decodeCloudinary();
  const mobileScreen = useSelector((state) => state.isMobile.value.isMobile);
  const numOfDisplay = mobileScreen ? 4 : defaultNumOfDisplay;
  const {
    product,
    mainImage,
    mainImageIndex,
    view3d,
    configuraMissingColors,
    filterExceptionList,
    optionsSelected,
  } = useSelector((state) => state?.productState?.value);
  const dispatch = useDispatch();
  const [openImages, setOpenImages] = useState(false);
  const images = product?.cdnLinks;
  const [index, setIndex] = useState([]);
  const [configuraSku, setConfiguraSku] = useState();

  const cld = new Cloudinary({
    cloud: {
      cloudName: 'da3rom333',
    },
  });

  const viewAllTop =
    mobileScreen ? '0px'
    : images?.length === 1 ? '185px'
    : images?.length === 2 ? '322px'
    : images?.length === 3 ? '460px'
    : '595px';
  const viewAllLeft =
    mobileScreen ?
      images?.length === 1 ? '21rem'
      : images?.length === 2 ? '38rem'
      : images?.length === 3 ? '55rem'
      : '55rem'
    : '1rem';
  function resetIndex() {
    var holderArray = [];
    for (let i = 0; i < numOfDisplay; i++) {
      holderArray.push(i);
    }
    setIndex(holderArray);
  }

  function addIndex() {
    var holderArray = [];
    if (index[numOfDisplay - 1] === images?.length - 1) {
      for (var i = 1; i < numOfDisplay; i++) {
        holderArray.push(index[i]);
      }
      holderArray.push(0);
      setIndex(holderArray);
    } else {
      for (var i = 1; i < numOfDisplay; i++) {
        holderArray.push(index[i]);
      }
      holderArray.push(index[numOfDisplay - 1] + 1);
      setIndex(holderArray);
    }
  }

  function checkIndex(key) {
    for (var i = 0; i < index?.length; i++) {
      if (index.includes(parseInt(key))) {
        return `index${index.indexOf(parseInt(key))}`;
      } else if (parseInt(key) > index[numOfDisplay - 1]) {
        return 'showNew';
      } else if (parseInt(key) < index[0]) {
        return 'showNew';
      }
    }
  }

  useEffect(() => {
    resetIndex();
  }, [images?.length]);

  //change view if the image displayed changes and if out of sight - for veritcal carousel on products page
  useEffect(() => {
    var counter = index[numOfDisplay - 1];
    if (mainImageIndex === 0) {
      resetIndex();
    }
    while (mainImageIndex > counter) {
      addIndex();
      counter++;
    }
  }, [mainImageIndex]);

  useEffect(() => {
    if (product?.productInfo?.configuraSeries) {
      setConfiguraSku(product?.productInfo?.configuraSeries);
    } else {
      Axios.post(`${apiAddress}/products/getConfiguraMissing`, {
        baseModelNum: product?.productInfo?.BaseModelNum,
      })
        .then((response) => {
          if (response.data.length === 0) {
            setConfiguraSku(
              product?.productInfo?.configuraMatch === 'Yes' ? product?.productInfo?.BaseModelNum
              : product?.productInfo?.configuraMatch === 'No' ?
                product?.productInfo?.BaseModelNum.replace(/x/g, '')
              : product?.productInfo?.configuraMatch
            );
          } else {
            setConfiguraSku(undefined);
          }
        })
        .catch((error) => {
          console.error('Error getting configura not found list:', error);
        });
    }
  }, [product]);

  return (
    <>
      <div
        style={{
          marginTop:
            (
              mobileScreen &&
              ((view3d && Object.keys(configuraMissingColors).length > 0) ||
                filterExceptionList?.some((i) => optionsSelected[i]))
            ) ?
              '14rem'
            : '',
        }}
        className={`vertical-carousel ${mobileScreen ? 'horizontal' : 'vertical'}`}
      >
        <div className='slider-image-div'>
          {images?.map((i, index) => (
            <div
              key={index}
              role='button'
              aria-label='Product'
              tabIndex={0}
              className={checkIndex(index)}
              onClick={() => dispatch(updateProductInfo({ view3d: false }))}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  dispatch(updateProductInfo({ view3d: false }));
                }
              }}
            >
              <div
                role='button'
                aria-label='Product'
                tabIndex={0}
                className='vertical-car-img car-img-div'
                onClick={() => {
                  dispatch(updateProductInfo({ mainImage: i.Image }));
                }}
                style={{
                  borderColor: `${mainImage === i.Image ? '#01425A' : ''}`,
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    dispatch(updateProductInfo({ mainImage: i.Image }));
                  }
                }}
              >
                <AdvancedImage
                  cldImg={cld.image(decodeCloudinaryURL(i.Image)).resize(scale(200))}
                  alt={`Different view of ${product.modelNum}`}
                />
              </div>
            </div>
          ))}
          {images?.length > 0 && (
            <>
              <div
                role='button'
                aria-label='View All Products'
                tabIndex={0}
                style={{
                  top: viewAllTop,
                  left: viewAllLeft,
                }}
                className='view-all vertical-car-img'
                onClick={() => {
                  setOpenImages(true);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setOpenImages(true);
                  }
                }}
              ></div>
              <h3
                role='button'
                aria-label='View All Products'
                tabIndex={0}
                onClick={() => {
                  setOpenImages(true);
                }}
                style={{
                  top: viewAllTop,
                  left: viewAllLeft,
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setOpenImages(true);
                  }
                }}
              >
                VIEW ALL
              </h3>
            </>
          )}
          {mobileScreen && configuraSku && (
            <button
              className={`vertical-car-img view-3d-toggle ${view3d ? 'selected-3d' : ''}`}
              onClick={() => {
                dispatch(updateProductInfo({ view3d: !view3d }));
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  dispatch(updateProductInfo({ view3d: !view3d }));
                }
              }}
            >
              <LuBox />
              <span>3D VIEW</span>
            </button>
          )}
        </div>
      </div>
      {openImages && images?.length > 0 ?
        <ViewAll setDownloadMedia={setOpenImages} />
      : ''}
    </>
  );
};

export default VerticalCarousel;
