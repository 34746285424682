const icon =
  'https://res.cloudinary.com/da3rom333/image/upload/v1689011225/Website%20Assets/GIFs/Loading_biviek.gif';
const LoadingIcon = () => {
  return (
    <img
      src={icon}
      style={{
        height: '10rem',
      }}
      alt='Loading Icon'
    />
  );
};

export default LoadingIcon;
