import { useEffect, useState } from 'react';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import EditTemplate from './EditTemplate';
const EditCategoryTree = () => {
  const [catValues, setCatValues] = useState([]);
  const [catArray, setCatArray] = useState();
  const [notice, setNotice] = useState();
  // const [parentValues, setParentValues] = useState([]);
  const axiosJWT = useAxiosJWT();
  function getCategories() {
    axiosJWT.get(`/adminPortal/getCats`).then((response) => {
      setCatValues(
        response.data.map((i) => {
          return i.category;
        })
      );
      setCatArray(response.data);
    });
  }

  function getCatID(cat) {
    const found = catArray.find((i) => {
      return i.category === cat;
    });

    return found.ID;
  }

  function addHandler(values) {
    axiosJWT
      .post(`adminPortal/addData`, {
        table: 'categorytree',
        values: [
          getCatID(values['Category']),
          getCatID(values['Parent Category']),
          values['Image Link'],
          values['Sort Order'],
        ],
        keys: ['cat', 'parentCat', 'image_link', 'sort_order'],
      })
      .then((response) => {
        setNotice(`DATA ADDED ID: ${response.data.insertId}`);
      })
      .catch((err) => {
        setNotice(`ERROR: ${err}`);
      });
  }

  function updateHandler(values) {
    // eslint-disable-next-line no-console
    console.log(values, getCatID(values['Parent Category']));

    axiosJWT
      .post(`adminPortal/updateData`, {
        table: 'categorytree',
        ID: values.ID,
        dataKey: 'parentCat',
        dataValue: getCatID(values['Parent Category']),
      })
      .then(() => {
        axiosJWT
          .post(`adminPortal/updateData`, {
            table: 'categorytree',
            ID: values.ID,
            dataKey: 'image_link',
            dataValue: values['Image Link'],
          })
          .then(() => {
            axiosJWT.post(`adminPortal/updateData`, {
              table: 'categorytree',
              ID: values.ID,
              dataKey: 'sort_order',
              dataValue: values['Sort Order'],
            });
          })
          .then(() => {
            axiosJWT.post(`adminPortal/updateData`, {
              table: 'categorytree',
              ID: values.ID,
              dataKey: 'linkParams',
              dataValue: values['Link Params'],
            });
          });
      })

      .then(() => {
        setNotice('DATA SAVED');
      })
      .catch((err) => {
        setNotice(`ERROR: ${err}`);
      });
  }
  useEffect(() => {
    getCategories();
  }, []);
  const columns = [
    {
      type: 'text',
      name: 'ID',
      searchAllowed: true,
      editable: false,
      table: 'categorytree',
      fieldName: 'ID',
      display: false,
    },
    {
      type: 'text',
      name: 'CatID',
      searchAllowed: true,
      editable: false,
      table: 'categorytree',
      fieldName: 'cat',
      display: false,
    },
    {
      type: 'dropdown',
      name: 'Category',
      searchAllowed: true,
      editable: false,
      table: 'category',
      fieldName: 'categories',
      display: true,
      values: catValues,
    },
    {
      type: 'text',
      name: 'ParentID',
      searchAllowed: true,
      editable: false,
      table: 'categorytree',
      fieldName: 'parentCat',
      display: false,
    },
    {
      type: 'dropdown',
      name: 'Parent Category',
      searchAllowed: true,
      editable: true,
      table: 'categories',
      fieldName: 'category',
      display: true,
      values: catValues,
    },
    {
      type: 'text',
      name: 'Sort Order',
      searchAllowed: true,
      editable: true,
      table: 'categorytree',
      fieldName: 'sort_order',
      display: true,
    },
    {
      type: 'text',
      name: 'Link Params',
      searchAllowed: true,
      editable: true,
      table: 'categorytree',
      fieldName: 'linkParams',
      display: true,
    },
  ];
  return (
    <EditTemplate
      columns={columns}
      getEndPoint={'getCatTree'}
      title={'Category Tree'}
      table={'categorytree'}
      addHandlerIncoming={addHandler}
      saveHandlerIncoming={updateHandler}
      localNotice={notice}
      setLocalNotice={setNotice}
    />
  );
};

export default EditCategoryTree;
