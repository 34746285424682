import { useState } from 'react';
import { Link } from 'react-router-dom';

const HeaderItem = ({ itemText, dropdownObject, openDefault, categories, link }) => {
  const [open, setOpen] = useState(openDefault);

  function mouseEnter() {
    setOpen(true);
  }

  function mouseExit() {
    if (!openDefault) {
      setOpen(false);
    }
  }

  // function handleBlur(event) {
  //   if (!event.currentTarget.contains(event.relatedTarget)) {
  //     setOpen(false);
  //   }
  // }

  return (
    <div>
      <li
        role='button'
        aria-label={itemText}
        tabIndex={0}
        className='nav-item'
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseExit}
        // onBlur={handleBlur}
        onClick={mouseExit}
        onKeyDown={() => {
          <Link to={`/${link}`} />;
        }}
      >
        <Link
          to={link}
          className='nav-item-a'
          state={itemText === 'PRODUCTS' ? { selectedCat: categories?.productList } : ''}
        >
          {itemText}
        </Link>
        {open ? dropdownObject : ''}
      </li>
    </div>
  );
};

export default HeaderItem;
