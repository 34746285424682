import { useEffect, useState } from 'react';
import { AiOutlineLink } from 'react-icons/ai';
import { BsFillPlayCircleFill } from 'react-icons/bs';
import { CgSoftwareDownload } from 'react-icons/cg';
import '../../CSS/Brochures.css';
// import { video } from "@cloudinary/url-gen/qualifiers/source";
import clipboardCopy from 'clipboard-copy';
import { ImEmbed2 } from 'react-icons/im';

const VideoCube = ({ videoData, setCurrentVideo, setOpen }) => {
  // const [playing, setPlaying] = useState(false);
  // useEffect(() => {
  //   if (currentVideo === videoData.videoLink) {
  //     setPlaying(true);
  //   } else {
  //     setPlaying(false);
  //   }
  // }, []);

  // const [thumbnailUrl, setThumbnailUrl] = useState('');
  // const fetchVimeoImage = async () => {
  //   try {
  //     const response = await fetch(
  //       `https://vimeo.com/api/v2/video/${videoData.videoID}.json`
  //     );
  //     const data = await response.json();
  //     const thumbnailUrl = data[0]?.thumbnail_large;
  //     setThumbnailUrl(thumbnailUrl);
  //   } catch (error) {
  //     console.error("Error fetching video details:", error);
  //   }
  // };
  // const fetchYoutubeImage = async () => {
  //   try {
  //     const response = await Axios.get(
  //       `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoData.videoID}&key=AIzaSyA0NAbo4OnD_xN9Fq3HaWsCAKZ4YM0ekVg`
  //     );
  //     const thumbnailUrl =
  //       response.data.items[0].snippet.thumbnails.maxres?.url ||
  //       response.data.items[0].snippet.thumbnails.high.url;
  //     setThumbnailUrl(thumbnailUrl);
  //   } catch (error) {
  //     console.error("Error fetching YouTube video thumbnail:", error);
  //   }
  // };

  useEffect(() => {
    // if (videoData.source.toLowerCase() === "vimeo") {
    //   fetchVimeoImage();
    // } else if (videoData.source.toLowerCase() === "youtube") {
    //   fetchYoutubeImage();
    // }
  }, [videoData]);

  const [copied, setCopied] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 400);
  }, [copied]);

  return (
    <div
      // style={{ height: "20.625rem", width: "28.625rem" }}
      className='brochure-tile video-tile'
      style={{ position: 'relative' }}
    >
      {copied ?
        <div
          className='notice'
          style={{ left: '37.5%' }}
        >
          {copied}
        </div>
      : ''}
      <div
        className='img-div'
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: 'none',
        }}
      >
        <img
          src={videoData?.videoLink?.replace('.mp4', '.jpg')}
          style={{ maxHeight: '100%', width: '100%' }}
          alt={videoData.title}
        ></img>
        <BsFillPlayCircleFill
          onClick={() => {
            setCurrentVideo(videoData.videoLink);
            setOpen(true);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setCurrentVideo(videoData.videoLink);
              setOpen(true);
            }
          }}
        />
      </div>

      <div className='video-title'>
        <h2>{videoData.videoType.toUpperCase()}</h2>
        <h1 style={{ textTransform: 'capitalize' }}>{videoData.title}</h1>
        <div
          className='mediaTile-buttons'
          style={{
            position: 'absolute',
            right: '1rem',
            bottom: '1rem',
            width: 'auto',
          }}
        >
          <div
            role='button'
            aria-label='download '
            tabIndex={0}
            className='download-img'
            onClick={() => {
              window.open(
                videoData.videoLink.replace('/upload', '/upload/fl_attachment'),
                '_blank'
              );
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                window.open(
                  videoData.videoLink.replace('/upload', '/upload/fl_attachment'),
                  '_blank'
                );
              }
            }}
          >
            <CgSoftwareDownload />
          </div>
          <div
            role='button'
            aria-label='download '
            tabIndex={0}
            className='download-img'
            onClick={() => {
              setCopied('Link Copied');
              clipboardCopy(videoData.videoLink);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setCopied('Link Copied');
                clipboardCopy(videoData.videoLink);
              }
            }}
          >
            <AiOutlineLink />
          </div>
          <div
            role='button'
            aria-label=''
            tabIndex={0}
            className='download-img'
            onClick={() => {
              setCopied('Embed Link Copied');
              clipboardCopy(
                `<iframe src="${videoData.videoLink}" frameborder="0" allow="accelerometer; autoplay; clipboard-write;" allowfullscreen></iframe>`
              );
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setCopied('Embed Link Copied');
                clipboardCopy(
                  `<iframe src="${videoData.videoLink}" frameborder="0" allow="accelerometer; autoplay; clipboard-write;" allowfullscreen></iframe>`
                );
              }
            }}
          >
            <ImEmbed2 />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCube;
