import { useEffect } from 'react';
import Inspire from '../../Components/Dealer Portal/Inspire';
import Partners from '../../Components/Dealer Portal/Partners';

const banner =
  'https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1688650796/Website%20Assets/Banners/DesignServices_px4s4p.jpg';
const SpacePlanning = () => {
  useEffect(() => {
    document.title = `Space Planning- National Public Seating`;
  }, []);
  return (
    <div className='space-planning'>
      <div className='banner'>
        <img
          src={banner}
          alt='Design Services Banner'
        ></img>
      </div>
      <div className='planSpace'>
        <button
          onClick={() => {
            window.open('https://forms.monday.com/forms/964a65debcbfd65aa4a86dd98192a95d?r=use1');
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              window.open('https://forms.monday.com/forms/964a65debcbfd65aa4a86dd98192a95d?r=use1');
            }
          }}
        >
          Start Planning
        </button>
      </div>
      <div style={{ height: '50rem' }}></div>
      <Inspire />
      <div
        className='coBranded'
        id='coBranded'
      >
        <button
          onClick={() => {
            window.open('https://forms.monday.com/forms/6182175287cb5cdcc82396a18cd53694');
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              window.open('https://forms.monday.com/forms/6182175287cb5cdcc82396a18cd53694');
            }
          }}
        >
          Submit Request
        </button>
      </div>
      <div style={{ height: '50rem' }}></div>
      <Partners />
    </div>
  );
};

export default SpacePlanning;
