import { AdvancedImage } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';

const OptionsCube = ({ option, optionType, optionsSelected, setOptionsSelected }) => {
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'da3rom333',
    },
  });

  function clicked() {
    setOptionsSelected({
      ...optionsSelected,
      [optionType.name]: option.selectionName,
    });
  }

  const myImage = cld.image(option.imageLink);
  return (
    <div
      role='button'
      aria-label={option.selectionName}
      tabIndex={0}
      className={`option-cube ${optionsSelected[optionType.name] === option.selectionName ? 'option-cube-clicked' : ''}`}
      onClick={() => {
        clicked();
      }}
      style={{
        cursor: 'pointer',
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          clicked();
        }
      }}
    >
      <AdvancedImage
        cldImg={myImage}
        style={{
          cursor: 'pointer',
          opacity: '1',
        }}
        alt={option.selectionName}
      />
      <div className='h4-div'></div>
    </div>
  );
};

export default OptionsCube;
