const ClaimButton = ({ i, selectedButton, setSelectedButton, scrollOnClick }) => {
  return (
    <div
      role='button'
      aria-label={i.title}
      tabIndex={0}
      className={`${i.title.replace(/ /g, '-').toLowerCase()} ${
        selectedButton === i.title.replace(/ /g, '-').toLowerCase() ? 'selected-button' : ''
      }`}
      onClick={() => {
        setSelectedButton(`${i.title.replace(/ /g, '-').toLowerCase()}`);
        scrollOnClick();
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          setSelectedButton(`${i.title.replace(/ /g, '-').toLowerCase()}`);
          scrollOnClick();
        }
      }}
    >
      <img
        alt={i.title}
        src={selectedButton === i.title.replace(/ /g, '-').toLowerCase() ? i.select_image : i.image}
      ></img>
      <h2>{i.title}</h2>
    </div>
  );
};

export default ClaimButton;
