import { useState } from 'react';
import '../../CSS/MediaCenter.css';
import '../../CSS/MobileApp/M_MediaCenter.css';

const videos =
  'https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1688653509/Website%20Assets/JPGs/Videos_hdne4c.png';
const nps =
  'https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1688653477/Website%20Assets/JPGs/Nps_rbfit5.png';
const gsa =
  'https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1688653477/Website%20Assets/JPGs/Gsa_ewbgjs.png';
const downloads =
  'https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1688653477/Website%20Assets/JPGs/Downloads_l9bsqz.png';
const brochures =
  'https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1688653478/Website%20Assets/JPGs/Brochures_q0qqkt.png';
const MediaCenterCube = ({ data, clickHandler }) => {
  function getImg() {
    switch (data.title) {
      case 'Media Downloads':
        return downloads;
      case 'Digital Catalog':
        return nps;
      case 'GSA Catalog':
        return gsa;
      case 'Brochures':
        return brochures;
      case 'Promo Videos':
        return videos;
      default:
        return null;
    }
  }
  const [hover, setHover] = useState(false);
  return (
    <div
      role='button'
      aria-label={data.title}
      tabIndex={0}
      className='mediaCenterCube'
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onClick={() => {
        clickHandler(data);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          clickHandler(data);
        }
      }}
    >
      <img
        src={getImg()}
        alt={data.title}
      />
      <div className='underline'></div>
      <button
        style={{
          background: hover ? 'var(--lightgreen)' : 'white',
          fontWeight: hover ? '700' : '600',
        }}
      >
        {data.title}
      </button>
    </div>
  );
};

export default MediaCenterCube;
