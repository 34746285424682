import { useEffect, useState } from 'react';
import '../../CSS/Contracts.css';
import useAxiosJWT from '../../hooks/useAxiosJWT';
const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;

const contactIcon =
  'https://res.cloudinary.com/da3rom333/image/upload/v1688653574/Website%20Assets/SVGs/contactIcon_knn7f7.svg';
const banner =
  'https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1688650794/Website%20Assets/Banners/Contracts_usro9m.jpg';
const Contracts = () => {
  useEffect(() => {
    document.title = `Contracts - National Public Seating`;
    window.scrollTo(0, 0);
  });
  const axiosJWT = useAxiosJWT();
  const [data, setData] = useState();

  async function getData() {
    await axiosJWT.get(`${apiAddress}/content/getGovernmentContracts`).then((response) => {
      setData(response.data);
    });
  }

  useEffect(() => {
    getData();
  }, []);

  function clickHandler(item) {
    window.open(item.pdf, '_blank');
  }
  return (
    <div className='contract-page'>
      <div className='banner'>
        <img
          src={banner}
          alt='Govement Contracts Banner'
        />
        <div className='bannerText'>
          National Public Seating products are available through many State and Regional Contracts.
        </div>
      </div>

      <div className='inner-padding'>
        <h3>National Contracts</h3>
        <div className='underline'></div>
        {data
          ?.filter((i) => {
            return i.type === 'National';
          })
          ?.map((i) => {
            return (
              <div
                role='button'
                aria-label={`Learn More About ${i.title}`}
                tabIndex={0}
                className='contract'
                key={i['Contract ID']}
                onClick={() => {
                  clickHandler(i);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    clickHandler(i);
                  }
                }}
              >
                <div className='image-div'>
                  <img
                    src={i.image}
                    alt={i.title}
                  />
                </div>
                <h4>{i.title}</h4>
                <h5>{i['Contract ID']}</h5>
                <h6>Learn More</h6>
              </div>
            );
          })}
      </div>
      <div className='inner-padding'>
        <h3 style={{ marginTop: '60px' }}>STATE AND AGENCY CONTRACTS</h3>
        <div className='underline'></div>
        {data
          ?.filter((i) => {
            return i.type === 'State';
          })
          ?.map((i) => {
            return (
              <div
                role='button'
                aria-label={`Learn More About ${i.title}`}
                tabIndex={0}
                className='contract'
                key={i['Contract ID']}
                onClick={() => {
                  clickHandler(i);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    clickHandler(i);
                  }
                }}
              >
                <div className='image-div'>
                  <img
                    src={i.image}
                    alt={i.title}
                  />
                </div>
                <h4>{i.title}</h4>
                <h5>{i['Contract ID']}</h5>
                <h6>Learn More</h6>
              </div>
            );
          })}
      </div>
      <div className='additionalInfo'>
        <div>
          <img
            src={contactIcon}
            alt='Contact Icon'
          />
          <h2>For additional information please contact</h2>
          <h1> Uri Salzman, Contracts Manager</h1>
          <button
            onClick={() => {
              window.open(
                `mailto:contracts@nationalpublicseating.com?subject=Additional Information: Government Contracts`
              );
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                window.open(
                  `mailto:contracts@nationalpublicseating.com?subject=Additional Information: Government Contracts`
                );
              }
            }}
          >
            Contact
          </button>
        </div>
      </div>
      <div style={{ height: '30rem' }}></div>
    </div>
  );
};

export default Contracts;
