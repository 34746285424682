import { useNavigate } from 'react-router';
const NotFound = ({ title }) => {
  const navigate = useNavigate();

  const notfound =
    'https://res.cloudinary.com/da3rom333/image/upload/v1690329899/Website%20Assets/Banners/404_Page_not_found_reu7lh.jpg';
  const categoryNotFound =
    'https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1703013338/Website%20Assets/Banners/404_Category_not_found_frsog3.jpg';
  return (
    <div style={{ marginTop: '6rem', position: 'relative' }}>
      {title === 'product' ?
        <img
          src={notfound}
          style={{ width: '100%' }}
          alt='Product Not Found'
        />
      : title === 'category' ?
        <img
          src={categoryNotFound}
          style={{ width: '100%' }}
          alt='Category Not Found'
        />
      : ''}
      <button
        className='go-home-button'
        onClick={() => {
          navigate('/');
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            navigate('/');
          }
        }}
        style={{ top: '33rem', position: 'absolute' }}
      >
        Go Home
      </button>
    </div>
  );
};

export default NotFound;
